import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-gray-100 text-gray py-6">
      <div className="container mx-auto px-6">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">
            <Link to="/">
              <img src={require('../static/img/ScheduleMyCrewLogo.png')} alt="Schedule My Crew" className="h-10"/>
            </Link>
          </div>

          <div className="flex space-x-6 text-xs font-medium">
            <p>&copy; 2024. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
