import { put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as types from "../actions/referenceTypes";
import * as actions from "../actions/referenceActions";
import config from "../../config";
// import { callApiWithToken } from "./apiHelperSaga";

function* fetchActiveReference() {
  // const apiCall = (token) => {
  //   return axios.post(
  //     config.APIURL.sitepropmanagerapi.dactive,
  //     {},
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );
  // };

  try {
    // const response = yield callApiWithToken(apiCall);
    const response = yield call(axios.post, config.APIURL.sitepropmanagerapi.dactive, {});
    yield put(actions.fetchReferenceSuccess(response));
  } catch (error) {
    yield put(actions.fetchReferenceFailure(error.message));
  }
}



function* proposalSaga() {
  yield takeEvery(types.FETCH_REFERENCE_REQUEST, fetchActiveReference);
}

export default proposalSaga;
