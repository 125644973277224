import React from "react";
import { Link } from "react-router-dom"; // For routing, if you are using react-router
import { useAuthContext } from "../context/AuthContext"; // Custom hook for authentication context (if applicable)

const Header = () => {
  const { isAuthenticated, login, logout } = useAuthContext();

  return (
    <header className="bg-gray-100 text-white py-4 px-6">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="text-2xl font-bold">
          <Link to="/">
            <img src={require('../static/img/ScheduleMyCrewLogo.png')} alt="Schedule My Crew" className="h-10"/>
          </Link>
        </div>

        {/* Navigation aaaaaaaaajjjjjjjjjjjjjjjjffffLinks */}
        <nav className="flex space-x-6 text-sm">
          {/* <Link to="/" className="text-gray-600 hover:text-gray-700">
            Home
          </Link> */}
          <Link to="/proposal" className="text-gray-600 hover:text-gray-700">
            Add Proposal
          </Link>
          {/* <Link to="/displayproposal" className="text-gray-600 hover:text-gray-700">
            Display Proposal
          </Link> */}
          <Link to="/proposallist" className="text-gray-600 hover:text-gray-700">
            Proposal List
          </Link>
          {/* <Link to="/additem" className="text-gray-600 hover:text-gray-700">
            Add Proposal
          </Link> */}
        </nav>

        {/* Logout Button */}
        {isAuthenticated ? (
          <button
            onClick={logout}
            className="bg-violet-600 hover:bg-violet-700 text-white px-4 py-2 rounded"
          >
            Logout
          </button>
        ) : (
          <button
            onClick={login}
            className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded"
          >
            Login
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
