import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReferenceRequest } from "../redux/actions/referenceActions";

const MultiSelectDropdown = ({ selectedItems, setSelectedItems }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.reference.data);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchReferenceRequest());
  }, [dispatch]);

  useEffect(() => {
    console.log(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      console.log(categories);
    }
  }, [categories]);

  const handleSelect = (category) => {
    if (!selectedItems.includes(category.id)) {
      setSelectedItems([...selectedItems, category.id]);
    }
  };

  const handleRemove = (id) => {
    setSelectedItems(selectedItems.filter((item) => item !== id));
  };

  return (
    <div className="relative w-full mx-auto">
      References
      <div
        className="border border-gray-300 rounded-md p-2 flex flex-wrap items-center gap-2 cursor-pointer bg-zinc-50"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {selectedItems.length === 0 ? (
          <span className="text-gray-500">Select References...</span>
        ) : (
          selectedItems.map((id) => {
            const category = categories.find((cat) => cat.id === id);
            return (
              <div
                key={id}
                className="bg-zinc-200 text-zinc-800 px-2 py-1 rounded flex items-center gap-1"
              >
                {category.managername}
                <button
                  className="text-red-500 font-bold hover:text-red-700"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemove(id);
                  }}
                >
                  &times;
                </button>
              </div>
            );
          })
        )}
      </div>
      {dropdownOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
          {categories.map((category) => (
            <div
              key={category.id}
              className={`p-2 hover:bg-gray-100 cursor-pointer ${
                selectedItems.includes(category.id)
                  ? "bg-gray-100 text-gray-400"
                  : ""
              }`}
              onClick={() => handleSelect(category)}
            >
              {category.companyname} ({category.managername})
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
