const BASEURL = process.env.REACT_APP_BASEURL;
const IMAGEURL = process.env.REACT_APP_IMAGEURL;


const APIURL = {
  emploginapi: {
    login: BASEURL + "/emploginapi/login",
    refresh: BASEURL + '/emploginapi/refresh',
  },
  proposalapi: {
    display: BASEURL + "/proposalapi/display",
    dactive:  BASEURL + "/proposalapi/dactive",
    displaybyid: BASEURL + "/proposalapi/displaybyid",
    create: BASEURL + "/proposalapi/create",
    photo: BASEURL + "/proposalapi/photo",
    activate: BASEURL + "/proposalapi/activate",
  },
  sitepropmanagerapi: {
    dactive: BASEURL + "/sitepropmanagerapi/dactive",

  }
};

// export default { BASEURL, IMAGEURL, APIURL };

const config = { BASEURL, IMAGEURL, APIURL };
export default config;
