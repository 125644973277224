
import * as types from './referenceTypes';

export const fetchReferenceRequest = () => ({
    type: types.FETCH_REFERENCE_REQUEST,
});

export const fetchReferenceSuccess = (data) => ({
    type: types.FETCH_REFERENCE_SUCCESS,
    payload: data,
});

export const fetchReferenceFailure = (error) => ({
    type: types.FETCH_REFERENCE_FAILURE,
    payload: error,
});

