import React from "react";
import { usePaymentContext } from "../context/PaymentContext";
import { getExtraData } from "../utility/functions";

const ExtraInfo = ({ emptype }) => {
  const { contextData } = usePaymentContext();

  const extraData = getExtraData(emptype, contextData);
  const { hoursYear, leapHoursYear, statHoursYear } = extraData;

  const totalYear = hoursYear + leapHoursYear + statHoursYear;

  return (
    <div className="w-full mx-auto h-96 flex items-center justify-center">
      <div className="w-full overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-blue-50">
              <th
                colSpan="2"
                className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider"
              >
                Extra Information
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                Regular Yearly Hours
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {hoursYear}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                Extra Calendar Day Hours
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {leapHoursYear}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                Statutory Holiday Hours
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {statHoursYear}
              </td>
            </tr>
            <tr className="bg-blue-50">
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                Total Hours
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {totalYear}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExtraInfo;
