// components/ProtectedRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import Header from "../common/Header";
import Footer from "../common/Footer";
import AxiosInterceptor from "./AxiosInterceptor";

AxiosInterceptor();

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuthContext();

  // console.log(location);

  if (!isAuthenticated) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return (
    <>
      <Header />
      <div className="protected-content">{children}</div>
      <Footer />
    </>
  );
};

export default ProtectedRoute;
