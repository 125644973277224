import * as types from './authTypes';

// Register actions
export const registerRequest = (user) => ({
  type: types.REGISTER_REQUEST,
  payload: user,
});

export const registerSuccess = (id) => ({
  type: types.REGISTER_SUCCESS,
  payload: { id },
});

export const registerFailure = (error) => ({
  type: types.REGISTER_FAILURE,
  payload: error,
});

// Login actions
export const loginRequest = (credentials) => ({
  type: types.LOGIN_REQUEST,
  payload: credentials,
});

export const loginSuccess = () => ({
  type: types.LOGIN_SUCCESS,
});

export const loginFailure = (error) => ({
  type: types.LOGIN_FAILURE,
  payload: error,
});
