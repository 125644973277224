import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { usePaymentContext } from "../context/PaymentContext";
import Cards from "./Cards";
import ExtraInfoCard from "./ExtraInfoCard";
import FinalCard from "./FinalCard";
import ClientCard from "./ClientCard";
import ExportData from "./ExportData";
// import ExportTable from './ExportTable';
// import FinalCard2 from "./FinalCard2";
const ProposalCards = ({ recordSaved }) => {
  const { paymentData } = usePaymentContext();
  const databyid = useSelector((state) => state.proposal.databyid);
  const { year2Percent, year3Percent, year4Percent, year5Percent } =
    paymentData;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (databyid && databyid.length > 0) {
      console.log(databyid);
      setData(databyid[0]);
    }
  }, [databyid]);

  // Define refs for both tables
  const dataRef1 = useRef(null);
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);

  // Determine the number of grid columns based on the conditions
  let gridColumnsClass = "grid-cols-1 md:grid-cols-4"; // Default to 4 columns

  if (year5Percent) {
    gridColumnsClass = "grid-cols-1 md:grid-cols-6"; // If year5Percent exists, use 6 columns
  } else if (year4Percent) {
    gridColumnsClass = "grid-cols-1 md:grid-cols-5"; // If only year4Percent exists, use 5 columns
  }
  return (
    <>
      <div className="container mx-auto bg-gray-100 p-2">
        {/* <div className="grid grid-cols-1 md:grid-cols-6 gap-6"> */}
        <div className={`grid ${gridColumnsClass} gap-2`}>
          <Cards year="1" />
          {year2Percent && <Cards year="2" />}
          {year3Percent && <Cards year="3" />}
          {year4Percent && <Cards year="4" />}
          {year5Percent && <Cards year="5" />}
          <ExtraInfoCard />
        </div>
        <div className="border-t-[10px] border-gray-200 m-5"></div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FinalCard dataRef={dataRef1} />
        </div>
        <div className="border-t-[10px] border-gray-200 m-5"></div>
        <div className="grid grid-cols-1 gap-6">
          <ClientCard chartRef1={chartRef1} chartRef2={chartRef2} />
        </div>
        <div className="border-t-[10px] border-gray-200 m-5"></div>
        <div className="grid grid-cols-1 gap-6">
          {recordSaved && (
            <ExportData
              data={data}
              dataRef1={dataRef1}
              chartRef1={chartRef1}
              chartRef2={chartRef2}
            />
          )}
          {/* <ExportTable /> */}
        </div>
      </div>
    </>
  );
};
export default ProposalCards;
