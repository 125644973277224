import * as types from "../actions/adminTypes";

const initialState = {
  data: null,
  error: null,
  loading: false,
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADMIN_LOGIN_REQUEST:
    case types.REFRESH_TOKEN_REQUEST:
    case types.FETCH_ADMIN_REQUEST:
    case types.ADD_ADMIN_REQUEST:
    case types.UPDATE_ADMIN_REQUEST:
    case types.DELETE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case types.REFRESH_TOKEN_SUCCESS:
      
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case types.FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.proposal,
      };
    case types.ADD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload],
      };
    case types.UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case types.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter((item) => item.id !== action.payload),
      };
    case types.ADMIN_LOGIN_FAILURE:
    case types.REFRESH_TOKEN_FAILURE:
    case types.FETCH_ADMIN_FAILURE:
    case types.ADD_ADMIN_FAILURE:
    case types.UPDATE_ADMIN_FAILURE:
    case types.DELETE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
