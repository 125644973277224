import React from "react";
import { usePaymentContext } from "../context/PaymentContext";
import { getTotalData, getYearPercent } from "../utility/functions";
import { formatCurrency } from "../utility/utility";

const TotalCard = ({ year }) => {
  const { paymentData, contextData } = usePaymentContext();

  const year2Percent = Number(paymentData.year2Percent);
  const year3Percent = Number(paymentData.year3Percent);
  const year4Percent = Number(paymentData.year4Percent);
  const year5Percent = Number(paymentData.year5Percent);

  const yearData = getTotalData(year, paymentData, contextData);
  const {
    total,
    hst,
    grandTotal, 
    monthlyTotal,
    s_payrate,
    c_payrate,
    p_payrate,

    s_payable,
    c_payable,
    p_payable,
    total_payable,

    s_grossprofit,
    c_grossprofit,
    p_grossprofit,
    total_grossprofit,
    profit_per,
  } = yearData;

  const newYearPercent = getYearPercent(year, year2Percent, year3Percent, year4Percent, year5Percent);

  return (
    <div className="container mx-auto">
      <div className="overflow-x-auto">
        <div className="m-2 uppercase tracking-wide text-sm text-indigo-500 font-semibold">
          Year {year} {newYearPercent !== 0 && `Increase ${newYearPercent}%`}
        </div>

        <table className="min-w-full bg-white">
          <tbody>
            <tr>
              <td className="w-3/5 py-2 px-2 border border-gray-200 text-sm font-semibold text-right bg-blue-50">
                Total
              </td>
              <td className="w-2/5 py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {formatCurrency(total)}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right bg-blue-50">
                HST
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {hst}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right bg-blue-50">
                GT
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {grandTotal}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right bg-blue-50">
                MP
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {monthlyTotal}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="border-t-[5px] border-gray-200"></div>

        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-blue-50">
              <th className="w-4/10 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider"></th>
              <th className="w-3/10 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Payable
              </th>
              <th className="w-3/10 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Gross Profit
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-xs font-medium">
                Site Supervisor @ {s_payrate}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {s_payable}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {s_grossprofit}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-xs font-medium">
                Concierge @ {c_payrate}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {c_payable}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {c_grossprofit}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-xs font-medium">
                Patrol @ {p_payrate}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {p_payable}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {p_grossprofit}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-xs font-medium">
                Total
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {total_payable}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {total_grossprofit}
              </td>
            </tr>
            <tr className="bg-blue-50">
              <td className="py-2 px-2 border border-gray-200 text-xs font-medium"></td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right"></td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {profit_per}%
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TotalCard;
