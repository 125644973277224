
import { combineReducers } from 'redux';
// import crudReducer from './crudReducer';
import adminReducer from './adminReducer';
import proposalReducer from './proposalReducer';
import referenceReducer from './referenceReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
    // crud: crudReducer,
    admin: adminReducer,
    proposal: proposalReducer,
    reference: referenceReducer,
    auth: authReducer,
});

export default rootReducer;
                