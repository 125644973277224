import moment from 'moment';

const getDateTime = (data, dateformat = 'YYYY-MM-DD') => {
  if (data !== '0001-01-01T00:00:00' && data !== null)
    return moment(data)
      .locale('en')
      .format(dateformat);
  else return moment()
  .locale('en')
  .format(dateformat);;
};

const formatCurrency = (amount) => {
  if(amount)
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
    else
    return '$0.00';
  };
  
const formatCurrencyRoundUp = (amount, decimals = 2) => {
  if (amount) {
    const roundedAmount = roundUp(amount, decimals);
    return roundedAmount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimals,
    });
  } else {
    return '$0.00';
  }
};

const formatCurrencyRoundDown = (amount, decimals = 2) => {
  if (amount) {
    const roundedAmount = roundUp(amount, decimals);
    return roundedAmount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimals,
    });
  } else {
    return '$0.00';
  }
};

  function roundUp(value, decimals=2) {
    const factor = Math.pow(10, decimals);
    return Math.ceil(value * factor) / factor;
  }

  const roundDown = (value, decimalPlaces=2) => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.floor(value * factor) / factor;
  };
  
  export {getDateTime, formatCurrency, formatCurrencyRoundUp, formatCurrencyRoundDown, roundUp, roundDown};