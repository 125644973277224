import React from "react";
import { usePaymentContext } from "../context/PaymentContext";
import ExtraInfo from "./ExtraInfo";

const ExtraInfoCard = ({ year }) => {
  const { paymentData } = usePaymentContext();
  const { patrolProfit, extraProfit1, extraProfit2 } = paymentData;
  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl min-w-[280px]">
      <div className="md:flex">
        <div className="w-full p-2">
          <ExtraInfo emptype={"Site Supervisor"} />
        </div>
      </div>
      <div className="border-t-[5px] border-gray-200"></div>
      <div className="md:flex">
        <div className="w-full p-2">
          <ExtraInfo emptype={"Concierge"} />
        </div>
      </div>
      {patrolProfit && (
        <>
          <div className="border-t-[5px] border-gray-200"></div>
          <div className="md:flex">
            <div className="w-full p-2">
              <ExtraInfo emptype={"Patrol"} />
            </div>
          </div>
        </>
      )}
      {extraProfit1 && (
        <>
          <div className="border-t-[5px] border-gray-200"></div>
          <div className="md:flex">
            <div className="w-full p-2">
              <ExtraInfo emptype={"Extra1"} />
            </div>
          </div>
        </>
      )}
      {extraProfit2 && (
        <>
          <div className="border-t-[5px] border-gray-200"></div>
          <div className="md:flex">
            <div className="w-full p-2">
              <ExtraInfo emptype={"Extra2"} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExtraInfoCard;
