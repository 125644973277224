import React from "react";
import { usePaymentContext } from "../context/PaymentContext";
import TotalCard from "./TotalCard";
import YearCard from "./YearCard";

const Cards = ({ year }) => {
  const { paymentData } = usePaymentContext();
  const { patrolProfit, extraProfit1, extraProfit2 } = paymentData;
  return (
    // <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
    <div className="mx-auto bg-white rounded-xl shadow-md">
      <div className="md:flex">
        <div className="p-2">
          <YearCard year={year} emptype={"Site Supervisor"} />
        </div>
      </div>
      <div className="border-t-[5px] border-gray-200"></div>
      <div className="md:flex">
        <div className="p-2">
          <YearCard year={year} emptype={"Concierge"} />
        </div>
      </div>
      {patrolProfit && (
        <>
          <div className="border-t-[5px] border-gray-200"></div>
          <div className="md:flex">
            <div className="p-2">
              <YearCard year={year} emptype={"Patrol"} />
            </div>
          </div>
        </>
      )}
      {extraProfit1 && (
        <>
          <div className="border-t-[5px] border-gray-200"></div>
          <div className="md:flex">
            <div className="p-2">
              <YearCard year={year} emptype={"Extra1"} />
            </div>
          </div>
        </>
      )}
      {extraProfit2 && (
        <>
          <div className="border-t-[5px] border-gray-200"></div>
          <div className="md:flex">
            <div className="p-2">
              <YearCard year={year} emptype={"Extra2"} />
            </div>
          </div>
        </>
      )}
      <div className="border-t-[5px] border-gray-200"></div>
      <div className="md:flex">
        <div className="w-full p-2">
          <TotalCard year={year} />
        </div>
      </div>
    </div>
  );
};

export default Cards;
