import React from "react";
import { usePaymentContext } from "../context/PaymentContext";
import { formatCurrency } from "../utility/utility";
import config from "../config";
// import { getDateTime } from "../utility/utility";
import {
  Document,
  Packer,
  Paragraph,
  ImageRun,
  TextRun,
  Header,
  Footer,
  Table,
  TableRow,
  TableCell,
  WidthType,
  AlignmentType,
  PageNumber,
  TextWrappingType,
  TextWrappingSide,
  TabStopType,
  TabStopPosition,
  VerticalAlign,
  PageOrientation,
  BorderStyle,
  ShadingType,
  HeightRule,
} from "docx";
import { saveAs } from "file-saver";
import backgroundImage from "../static/img/imagebg.png";
import overlayImage from "../static/img/pillar.png";
import waterMarkImage from "../static/img/pillar-watermark.jpg";
// import pillarImage1 from "../static/img/pillar-page-1.jpg";
// import pillarImage2 from "../static/img/pillar-page-2.jpg";
import pillarImage5 from "../static/img/pillar-page-5.jpg";
import pillarImage6 from "../static/img/pillar-page-6.jpg";
import pillarImage8 from "../static/img/pillar-page-8.jpg";
import pillarImage12 from "../static/img/pillar-page-12.jpg";
import pillarImage4_1 from "../static/img/pillar-page-4-1.jpg";
import pillarImage4_2 from "../static/img/pillar-page-4-2.jpg";
import pillarImage15 from "../static/img/pillar-page-15.jpg";
import pillarImage18_1 from "../static/img/pillar-page-18-1.jpg";
import pillarImage18_2 from "../static/img/pillar-page-18-2.jpg";

import phoneIcon from "../static/img/call.png";
import emailIcon from "../static/img/email.png";
import webIcon from "../static/img/website.png";

// import pillarImage3 from "../static/img/pillar-3.jpg";

const ExportData =({ data, dataRef1, chartRef1, chartRef2 }) => {
  const { paymentData, contextData } = usePaymentContext();

  const {
    // s_TotalHoursYear,
    s_Payrate1,
    s_HoursYear,
    s_LeapHoursYear,
    s_BillingRate1,
    s_StatHoursYear,
    s_StatBillingRate1,
    c_Payrate1,
    c_HoursYear,
    c_LeapHoursYear,
    c_BillingRate1,
    c_StatHoursYear,
    c_StatBillingRate1,
    p_Payrate1,
    p_HoursYear,
    p_LeapHoursYear,
    p_BillingRate1,
    p_StatHoursYear,
    p_StatBillingRate1,
    e1_Payrate1,
    e1_HoursYear,
    e1_LeapHoursYear,
    e1_BillingRate1,
    e1_StatHoursYear,
    e1_StatBillingRate1,
    e2_Payrate1,
    e2_HoursYear,
    e2_LeapHoursYear,
    e2_BillingRate1,
    e2_StatHoursYear,
    e2_StatBillingRate1,
  } = contextData;

  const {
    corpNo,
    siteName,
    address,
    city,
    province,
    postalCode,
    sitePic,
    // date_Created,

    // supervisorHours,
    // conciergeHours,
    // patrolHours,
    // extraHours1,
    // extraHours2,
    supervisorTitle,
    conciergeTitle,
    patrolTitle,
    extraTitle1,
    extraTitle2,
    patrolProfit,
    extraProfit1,
    extraProfit2,
    hst,
    unionAmt,
    vacationAmt,
  } = paymentData;

  // const totalhours_reg_week =
  //   supervisorHours + conciergeHours + patrolHours + extraHours1 + extraHours2;

  const s_RegLeapHours = s_HoursYear + s_LeapHoursYear || 0;
  const s_RegLeapAmount = s_BillingRate1 * s_RegLeapHours || 0;
  const s_StatAmount = s_StatBillingRate1 * s_StatHoursYear || 0;

  const c_RegLeapHours = c_HoursYear + c_LeapHoursYear || 0;
  const c_RegLeapAmount = c_BillingRate1 * c_RegLeapHours || 0;
  const c_StatAmount = c_StatBillingRate1 * c_StatHoursYear || 0;

  const p_RegLeapHours = p_HoursYear + p_LeapHoursYear || 0;
  const p_RegLeapAmount = p_BillingRate1 * p_RegLeapHours || 0;
  const p_StatAmount = p_StatBillingRate1 * p_StatHoursYear || 0;

  const e1_RegLeapHours = e1_HoursYear + e1_LeapHoursYear || 0;
  const e1_RegLeapAmount = e1_BillingRate1 * e1_RegLeapHours || 0;
  const e1_StatAmount = e1_StatBillingRate1 * e1_StatHoursYear || 0;

  const e2_RegLeapHours = e2_HoursYear + e2_LeapHoursYear || 0;
  const e2_RegLeapAmount = e2_BillingRate1 * e2_RegLeapHours || 0;
  const e2_StatAmount = e2_StatBillingRate1 * e2_StatHoursYear || 0;

  var preTaxAmount;
  if (!patrolProfit)
    preTaxAmount =
      s_RegLeapAmount +
      s_StatAmount +
      c_RegLeapAmount +
      c_StatAmount +
      unionAmt +
      vacationAmt;
  else if (patrolProfit)
    preTaxAmount =
      s_RegLeapAmount +
      s_StatAmount +
      c_RegLeapAmount +
      c_StatAmount +
      p_RegLeapAmount +
      p_StatAmount +
      unionAmt +
      vacationAmt;

  const hstAmount = (preTaxAmount * hst) / 100;
  const grandTotal = Number(preTaxAmount) + Number(hstAmount);
  const monthlyTotal = grandTotal / 12;

  const watermark = async () => {
    const waterMarkResponse = await fetch(waterMarkImage);
    const waterMarkBlob = await waterMarkResponse.blob();
    const waterMarkBuffer = await waterMarkBlob.arrayBuffer();

    return new Paragraph({
      children: [
        new ImageRun({
          data: waterMarkBuffer, // Image data for watermark
          transformation: {
            width: 100, // Adjust watermark width
            height: 53, // Adjust watermark height
          },
          floating: {
            horizontalPosition: {
              relative: "page",
              align: "center", // Center horizontally
            },
            verticalPosition: {
              relative: "page",
              align: "center", // Center vertically
            },
            wrap: {
              type: TextWrappingType.NONE, // Ensure it's behind the text
            },
            behindDocument: true,
            margins: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          },
        }),
      ],
    });
  };

  const createHeading = (text, options = {}) => {
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${text}`,
                      color: options.color || "000000",
                      bold: options.bold || false,
                      font: "Helvetica",
                      size: options.size || 20,
                    }),
                  ],
                  alignment: options.alignment || AlignmentType.LEFT,
                  borders: {
                    top: { style: BorderStyle.NONE },
                    bottom: { style: BorderStyle.NONE },
                    left: { style: BorderStyle.NONE },
                    right: { style: BorderStyle.NONE },
                  },
                }),
              ],
              shading: {
                type: ShadingType.CLEAR,
                fill: "FFC000",
              },
              margins: {
                top: 50,
                bottom: 50,
                left: 0,
                right: 0,
              },
            }),
          ],
        }),
      ],
      borders: {
        top: { style: BorderStyle.NONE },
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  };

  // const createParagraph = (text, options = {}) => {
  //   return new Paragraph({
  //     children: [
  //       new TextRun({
  //         text: `${text}`,
  //         color: options.color || "000000",
  //         bold: options.bold || false,
  //         font: "Helvetica",
  //         size: options.size || 20,
  //       }),
  //     ],
  //     alignment: options.alignment || AlignmentType.JUSTIFIED,
  //     spacing: { before: options.spacingbefore, after: options.spacingafter },
  //   });
  // };

  const createParagraph = (text, options = {}) => {
    // Split the text by newline characters (\n)
    const lines = text.split("\n");

    // Map each line to a TextRun and add a break after each line (except the last)
    const children = lines.flatMap((line, index) => {
      const textRun = new TextRun({
        text: line,
        color: options.color || "000000",
        bold: options.bold || false,
        font: "Helvetica",
        size: options.size || 20,
      });

      // Add a line break after each line except the last one
      return index < lines.length - 1
        ? [textRun, new TextRun({ break: 1 })]
        : [textRun];
    });

    return new Paragraph({
      children,
      alignment: options.alignment || AlignmentType.JUSTIFIED,
      spacing: { before: options.spacingbefore, after: options.spacingafter },
    });
  };

  const createTabStopText = (textLeft, textRight, options = {}) => {
    return new Paragraph({
      tabStops: [
        {
          type: TabStopType.RIGHT,
          position: TabStopPosition.MAX,
        },
      ],
      children: [
        new TextRun({
          text: `${textLeft}`,
          color: options.color || "000000",
          bold: options.bold || false,
          font: "Helvetica",
          size: options.size || 20,
        }),

        new TextRun({
          text: "\t",
          size: options.size || 20,
        }),

        new TextRun({
          text: `${textRight}`,
          color: options.color || "000000",
          bold: options.bold || false,
          font: "Helvetica",
          size: options.size || 20,
        }),
      ],
    });
  };

  const createImage = (imagename, options = {}) => {
    return new Paragraph({
      children: [
        new ImageRun({
          data: imagename,
          transformation: {
            width: options.width || 500,
            height: options.height || 300,
          },
        }),
      ],
      alignment: AlignmentType.CENTER,
    });
  };

  const createParagraphImage = (text, imagename, options = {}) => {
    return new Paragraph({
      children: [
        new ImageRun({
          data: imagename,
          transformation: {
            width: options.width || 500,
            height: options.height || 300,
          },
          floating: {
            horizontalPosition: {
              relative: "page",
              align: "left", // Center horizontally
            },
            verticalPosition: {
              relative: "line",
              align: "top", // Center vertically
            },
            wrap: {
              type: TextWrappingType.SQUARE,
              side: TextWrappingSide.RIGHT,
            },
          },
        }),
        new TextRun({
          text: "  ", // Add a space
          size: options.size || 20,
        }),

        new TextRun({
          text: `${text}`,
          color: options.color || "000000",
          bold: options.bold || false,
          font: "Helvetica",
          size: options.size || 20,
        }),

      ],
      alignment: options.alignment || AlignmentType.BOTH,
      spacing: { before: options.spacingbefore, after: options.spacingafter },

    });
  };

  const createHeadParargraph = (head, text, options = {}) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: `${head}`,
          bold: true,
          font: "Helvetica",
          size: 20,
        }),
        new TextRun({
          text: `${text}`,
          color: options.color || "000000",
          bold: options.bold || false,
          font: "Helvetica",
          size: options.size || 20,
        }),
      ],
      alignment: options.alignment || AlignmentType.JUSTIFIED,
      spacing: { before: options.spacingbefore, after: options.spacingafter },
    });
  };

  const createTickBullet = (text, options = {}) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: `✓ ${text}`,
          bold: options.bold || false,
          font: "Helvetica",
          size: options.size || 20,
        }),
      ],
      alignment: options.alignment || AlignmentType.JUSTIFIED,
      indent: {
        left: options.indentleft || 720,
        hanging: options.hanging || 250,
      },
    });
  };

  const createTickHeadParargraph = (head, text, options = {}) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: `✓ ${head}`,
          bold: options.bold || true,
          font: "Helvetica",
          size: options.size || 20,
        }),
        new TextRun({
          text: `${text}`,
          color: options.color || "000000",
          bold: options.bold || false,
          font: "Helvetica",
          size: options.size || 20,
        }),
      ],
      alignment: options.alignment || AlignmentType.JUSTIFIED,
      spacing: { before: options.spacingbefore, after: options.spacingafter },
      indent: {
        left: 720,
        hanging: 250,
      },
    });
  };

  const createCell = (text, options = {}) => {
    return new TableCell({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: text,
              bold: options.bold || false,
              font: "Helvetica",
              size: 20,
            }),
          ],
          alignment: options.alignment || undefined,
        }),
      ],
      margins: {
        top: 150,
        bottom: 150,
        left: 150,
        right: 150,
      },
      columnSpan: options.colspan || 1,
      rowSpan: options.rowspan || 1,
      borders: {
        top: { style: BorderStyle.SINGLE },
        bottom: { style: BorderStyle.SINGLE },
        left: { style: BorderStyle.SINGLE },
        right: { style: BorderStyle.SINGLE },
      },
    });
  };

  const dataTable = async () => {
    return new Table({
      rows: [
        new TableRow({
          children: [
            createCell(supervisorTitle + " @ $" + s_Payrate1, { bold: true }),
            createCell("Yearly Hours", { bold: true }),
            createCell("Billing Rate", { bold: true }),
            createCell("Total", { bold: true }),
          ],
        }),

        new TableRow({
          children: [
            createCell("Regular + Leap Hours"), // Rowspan
            createCell(String(s_RegLeapHours)),
            createCell(String(formatCurrency(s_BillingRate1))),
            createCell(String(formatCurrency(s_RegLeapAmount)), {
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("Statutory Holiday Hours"), // Rowspan
            createCell(String(s_StatHoursYear)),
            createCell(String(formatCurrency(s_StatBillingRate1))),
            createCell(String(formatCurrency(s_StatAmount)), {
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell(conciergeTitle + " @ $" + c_Payrate1, { bold: true }),
            createCell("Yearly Hours", { bold: true }),
            createCell("Billing Rate", { bold: true }),
            createCell("Total", { bold: true }),
          ],
        }),

        new TableRow({
          children: [
            createCell("Regular + Leap Hours"), // Rowspan
            createCell(String(c_RegLeapHours)),
            createCell(String(formatCurrency(c_BillingRate1))),
            createCell(String(formatCurrency(c_RegLeapAmount)), {
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("Statutory Holiday Hours"), // Rowspan
            createCell(String(c_StatHoursYear)),
            createCell(String(formatCurrency(c_StatBillingRate1))),
            createCell(String(formatCurrency(c_StatAmount)), {
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        ...(patrolProfit
          ? [
              new TableRow({
                children: [
                  createCell(`${patrolTitle} @ ${formatCurrency(p_Payrate1)}`, {
                    bold: true,
                  }),
                  createCell("Yearly Hours", { bold: true }),
                  createCell("Billing Rate", { bold: true }),
                  createCell("Total", { bold: true }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Regular + Leap Hours"), // Rowspan
                  createCell(String(p_RegLeapHours)),
                  createCell(String(formatCurrency(p_BillingRate1))),
                  createCell(String(formatCurrency(p_RegLeapAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  createCell("Statutory Holiday Hours"), // Rowspan
                  createCell(String(p_StatHoursYear)),
                  createCell(String(formatCurrency(p_StatBillingRate1))),
                  createCell(String(formatCurrency(p_StatAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),
            ]
          : []),

        ...(extraProfit1
          ? [
              new TableRow({
                children: [
                  createCell(extraTitle1 + " @ $" + e1_Payrate1, {
                    bold: true,
                  }),
                  createCell("Yearly Hours", { bold: true }),
                  createCell("Billing Rate", { bold: true }),
                  createCell("Total", { bold: true }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Regular + Leap Hours"), // Rowspan
                  createCell(String(e1_RegLeapHours)),
                  createCell(String(formatCurrency(e1_BillingRate1))),
                  createCell(String(formatCurrency(e1_RegLeapAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Statutory Holiday Hours"), // Rowspan
                  createCell(String(e1_StatHoursYear)),
                  createCell(String(formatCurrency(e1_StatBillingRate1))),
                  createCell(String(formatCurrency(e1_StatAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),
            ]
          : []),

        ...(extraProfit2
          ? [
              new TableRow({
                children: [
                  createCell(extraTitle2 + " @ $" + e2_Payrate1, {
                    bold: true,
                  }),
                  createCell("Yearly Hours", { bold: true }),
                  createCell("Billing Rate", { bold: true }),
                  createCell("Total", { bold: true }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Regular + Leap Hours"), // Rowspan
                  createCell(String(e2_RegLeapHours)),
                  createCell(String(formatCurrency(e2_BillingRate1))),
                  createCell(String(formatCurrency(e2_RegLeapAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),

              new TableRow({
                children: [
                  createCell("Statutory Holiday Hours"), // Rowspan
                  createCell(String(e2_StatHoursYear)),
                  createCell(String(formatCurrency(e2_StatBillingRate1))),
                  createCell(String(formatCurrency(e2_StatAmount)), {
                    alignment: AlignmentType.RIGHT,
                  }),
                ],
              }),
            ]
          : []),

        new TableRow({
          children: [
            createCell("", { colspan: 2 }),
            createCell("Total pre-Tax", { bold: true }),
            createCell(String(formatCurrency(preTaxAmount)), {
              bold: true,
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("", { colspan: 2 }),
            createCell("HST @13%", { bold: true }),
            createCell(String(formatCurrency(hstAmount)), {
              bold: true,
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("", { colspan: 2 }),
            createCell("Grand Total", { bold: true }),
            createCell(String(formatCurrency(grandTotal)), {
              bold: true,
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),

        new TableRow({
          children: [
            createCell("", { colspan: 2 }),
            createCell("Monthly Invoice", { bold: true }),
            createCell(String(formatCurrency(monthlyTotal)), {
              bold: true,
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  };

  const dataExtraTable = async () => {
    return new Table({
      rows: [
        new TableRow({
          children: [
            createCell("Hourly Billing Rate"),
            createCell("Statutory Holiday Rate"),
            createCell("Hourly Overtime Rate"),
            createCell("Less than 24hrs Notice (First 4 Hours)"),
            createCell("Less than 4hrs Notice (First 4 Hours)"),
          ],
        }),

        new TableRow({
          children: [
            createCell("$27.00", { bold: true }),
            createCell("$67.50", { bold: true }),
            createCell("$40.50", { bold: true }),
            createCell("$40.50", { bold: true }),
            createCell("$67.50", { bold: true }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  };

  const sectionBorders = {
    pageBorderLeft: {
      style: BorderStyle.SINGLE,
      size: 6,
      color: "000000",
      space: 600,
    },
    pageBorderRight: {
      style: BorderStyle.SINGLE,
      size: 6,
      color: "000000",
      space: 600,
    },
    pageBorderTop: {
      style: BorderStyle.SINGLE,
      size: 6,
      color: "000000",
      space: 600,
    },
    pageBorderBottom: {
      style: BorderStyle.SINGLE,
      size: 6,
      color: "000000",
      space: 600,
    },
    pageBorders: {
      display: "allPages",
      offsetFrom: "page",
      zOrder: "front",
    },
  };

  const commonPageProperties = {
    size: {
      // width: 11906,
      // height: 16838,
      //Letter Page Size (8.5 x 11 inches )
      width: 12240,
      height: 15840,
      orientation: PageOrientation.PORTRAIT,
    },
    margin: {
      // top: 1000,
      // right: 1000,
      // bottom: 400,
      // header: 300,
      // footer: 180,
      // left: 1000,

      top: 1070,
      right: 1070,
      bottom: 1070,
      left: 1070,
    },
    borders: sectionBorders,
  };

  const tableMargins = {
    top: 50,
    bottom: 50,
    left: 50,
    right: 50,
  };

  const preferredWidth = {
    size: 33.33,
    type: WidthType.PERCENTAGE,
  };

  const createNestedTable = (heading, tabledata, options = {}) => {
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                createParagraph(heading, {
                  bold: true,
                  size: 20,
                  alignment: AlignmentType.CENTER,
                }),
              ],
              shading: {
                type: ShadingType.CLEAR,
                fill: "ffda96",
              },
              margins: {
                top: 200,
                bottom: 200,
                left: 100,
                right: 100,
              },
              borders: {
                top: { style: BorderStyle.SINGLE, size: 10, color: "ffda96" },
                bottom: {
                  style: BorderStyle.SINGLE,
                  size: 10,
                  color: "ffda96",
                },
                left: { style: BorderStyle.SINGLE, size: 10, color: "ffda96" },
                right: { style: BorderStyle.SINGLE, size: 10, color: "ffda96" },
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: tabledata.map((data) =>
                createTickBullet(data.text, {
                  alignment: AlignmentType.LEFT,
                  indentleft: 240,
                  hanging: 240,
                })
              ),
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
                right: 50,
              },
              borders: {
                top: { style: BorderStyle.NONE },
                bottom: {
                  style: BorderStyle.SINGLE,
                  size: 10,
                  color: "ffda96",
                },
                left: { style: BorderStyle.SINGLE, size: 10, color: "ffda96" },
                right: { style: BorderStyle.SINGLE, size: 10, color: "ffda96" },
              },
            }),
          ],
          height: {
            value: options.height || 2000,
            rule: HeightRule.EXACT,
          },
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: { style: BorderStyle.SINGLE, size: 2 },
        bottom: { style: BorderStyle.SINGLE, size: 2 },
        left: { style: BorderStyle.SINGLE, size: 2 },
        right: { style: BorderStyle.SINGLE, size: 2 },
      },
    });
  };

  const tabledata1 = [
    { text: "Vacation Pay of 4%" },
    { text: "Overtime Pay" },
    { text: "Paid Uniform" },
    { text: "Paid in-class and on-site training." },
  ];
  const tabledata2 = [
    { text: "Employee life $20,000.00" },
    { text: "Employee Accidental Death and Dismemberment $20,000.00" },
    { text: "Dental 80% coverage" },
    { text: "Medical 80% coverage" },
    { text: "Vision care" },
    { text: "Chiropractor" },
    { text: "Physiotherapist Care" },
  ];
  const tabledata3 = [
    { text: "Canada Pension Plan Premiums" },
    { text: "Employers Health Tax" },
    { text: "Employment Insurance Premiums" },
    { text: "Workers Compensation Premiums" },
  ];
  const tabledata14_1 = [
    { text: "Fire Alarms and announcements – Fire Alarm testing" },
    { text: "Evacuation including those who need assistance." },
    { text: "Emergency exits" },
    { text: "Medical emergencies" },
    { text: "Flood" },
    { text: "Break-and-enter." },
    { text: "Mechanical failure which endangers people" },
    { text: "Major Municipal by-law infractions" },
    { text: "Hazardous spills (gasoline, chlorine, etc.)" },
    { text: "Abandoned / lost children / seniors." },
    { text: "Assist EMS" },
    { text: "Assault" },
    { text: "Disturbed persons" },
    { text: "Drowning" },
    { text: "Illegal drugs use and trafficking." },
    { text: "Drunkenness" },
    { text: "Disturbances" },
    { text: "Fatalities" },
    { text: "Mischief" },
    { text: "Natural Disasters" },
    { text: "Personal injury" },
  ];
  const tabledata14_2 = [
    {
      text: "There are four methods of entry to the building by non-residents, one, Break and Enter, two, Entry authorized by Property Management Office, three, entry authorized by a resident, four, entry by security in which case one or two must be satisfied.",
    },
    { text: "Greet direct, sign in Guest(s), answer pertinent questions." },
    { text: "Monitor access system for unauthorized entry." },
    { text: "Monitor surveillance cameras for unauthorized entry." },
    { text: "Verify status of doors that should be locked." },
    { text: "Receive trades, technicians (cable, etc.) realtors." },
    { text: "Assist elderly and handicapped persons." },
  ];
  const tabledata14_3 = [
    { text: "Common Elements" },
    { text: "Advertisements" },
    { text: "Bicycles" },
    { text: "Deliveries and Trades people" },
    { text: "Door locks" },
    { text: "Entry phone" },
    { text: "Exercise Room & Saunas" },
    { text: "Garbage Disposal & Recycling" },
    { text: "Parking for Residents" },
    { text: "Parking for Visitors" },
    { text: "Party Room" },
    { text: "Pets" },
    { text: "Sales of Suites" },
    { text: "Vehicle Repairs and Service/Washing" },
    { text: "Fire Procedures & Regulations" },
    { text: "Swimming Pool" },
  ];
  const tabledata14_4 = [
    { text: "Ensure residents use only assigned spaces." },
    { text: "Warning ticketing of unauthorized vehicle(s)" },
    { text: "Contact city to issue parking tickets." },
    { text: "Contact towing company for authorized towing" },
    { text: "Enforce corporation rules." },
    { text: "Report storage or restricted vehicles in spaces" },
  ];
  const tabledata14_5 = [
    { text: "Open garage door" },
    { text: "Issue parking permit once authorized." },
    { text: "Patrol Visitor Parking ensuring vehicle have been authorized." },
    { text: "Warning ticketing of unauthorized vehicle(s)" },
    { text: "Contact City to issue parking tickets." },
    { text: "Contact towing company for authorized towing" },
    { text: "Ensure Fire Routes are clear." },
    { text: "Enforce corporation rules." },
    { text: "Register vehicles." },
  ];
  const tabledata14_6 = [
    { text: "Receive package(s)" },
    { text: "Register package(s)" },
    { text: "Sign on behalf of resident if/when authorized." },
    { text: "Notify resident of package arrival" },
    { text: "Have resident sign for package at pick-up." },
  ];
  const tabledata14_7 = [
    { text: "Burnt out light bulbs." },
    { text: "Fire cabinets and equipment" },
    { text: "Hazardous sapills" },
    { text: "Fire pulls stations." },
    { text: "Smoke detectors" },
    { text: "Heat detectors" },
    { text: "Water leaks" },
    { text: "Unauthorized persons" },
    { text: "Unit door locks (visual)" },
    { text: "Emergency exits" },
    { text: "Materials storage in unauthorized areas." },
  ];
  const tabledata14_8 = [
    { text: "Receiving complaint" },
    { text: "Investigating complaint" },
    { text: "Requesting that noise be reduced" },
    { text: "Follow up with complainant." },
    { text: "Log report of incident" },
  ];
  const tabledata14_9 = [
    { text: "Pre and post use inspections of facilities." },
    { text: "Enforce rules and regulations to maintain order and safety." },
    {
      text: "Address any noise disturbances, misconduct, or unauthorized activities.",
    },
    {
      text: "Respond promptly to incidents, emergencies, or security-related concerns.",
    },
    {
      text: "Keep detailed records and documentation of incidents, inspections, and actions taken.",
    },
  ];
  const tabledata14_10 = [
    { text: "Issue and receipt" },
    { text: "Daily inventory" },
    { text: "Securing keys" },
  ];
  const tabledata14_11 = [
    { text: "Securing lost items" },
    { text: "Return to owner." },
    { text: "Logging of same" },
  ];
  const tabledata14_12 = [
    { text: "Patrols are 15 minutes in duration." },
    { text: "Patrols conducted once per hour." },
    { text: "Any anomalies are logged and reported." },
    { text: "Report of any safety hazard(s)" },
  ];
  const tabledata14_13 = [
    {
      text: "Daily Security Reports by Site Supervisor to Property Management",
    },
    {
      text: "Quarterly Reports to Board of Directors by Pillar President and Operations Manager",
    },
    { text: "Log reports to Aquarius" },
    { text: "Mechanical Logs" },
    { text: "Incident Logs" },
    { text: "Parking Logs" },
    { text: "Patrol Logs" },
    { text: "Other Logs" },
  ];
  const tabledata14_14 = [
    { text: "Suspicion" },
    { text: "Negative Thinking" },
    { text: "Temper" },
    { text: "Faith" },
    { text: "Prejudice" },
    { text: "Self Esteem" },
    { text: "Flexibility" },
    { text: "Dress and Deportment" },
  ];
  const tabledata14_15 = [
    { text: "Security Guard Authority" },
    { text: "Criminal Offences" },
    { text: "Arrest" },
    { text: "Other Powers of Arrest" },
    { text: "Use of Force" },
    { text: "Break and Enter / Theft Investigation" },
    { text: "Evidence Investigation" },
    { text: "Search of Area" },
    { text: "Search of Suspects" },
    { text: "Protection and Preservation of Evidence" },
  ];

  const generateDocument = async () => {
    const watermarkParagraph = await watermark();
    const dataTableParagraph = await dataTable();
    const dataExtraTableParagraph = await dataExtraTable();
    // Fetch the background image
    const bgResponse = await fetch(backgroundImage);
    const bgBlob = await bgResponse.blob();
    const bgArrayBuffer = await bgBlob.arrayBuffer();

    // Fetch the overlay image
    const overlayResponse = await fetch(overlayImage);
    const overlayBlob = await overlayResponse.blob();
    const overlayArrayBuffer = await overlayBlob.arrayBuffer();

    // const pImageResponse1 = await fetch(pillarImage1);
    // const pImageBlob1 = await pImageResponse1.blob();
    // const pImageBuffer1 = await pImageBlob1.arrayBuffer();

    // const response = await fetch(imageUrl);
    // const imageBlob = await response.blob();
    // const pImageBuffer2 = await imageBlob.arrayBuffer();
    
    // const pImageResponse2 = await fetch(pillarImage2);

    // const imageUrl = "http://localhost:60200/api/imageproxyapi/image/" + sitePic; 
    const imageUrl = config.IMAGEURL +"/" + sitePic; 

    const pImageResponse2 = await fetch(imageUrl);
    const pImageBlob2 = await pImageResponse2.blob();
    const pImageBuffer2 = await pImageBlob2.arrayBuffer();

    const pImageResponse4_1 = await fetch(pillarImage4_1);
    const pImageBlob4_1 = await pImageResponse4_1.blob();
    const pImageBuffer4_1 = await pImageBlob4_1.arrayBuffer();

    const pImageResponse4_2 = await fetch(pillarImage4_2);
    const pImageBlob4_2 = await pImageResponse4_2.blob();
    const pImageBuffer4_2 = await pImageBlob4_2.arrayBuffer();

    const pImageResponse5 = await fetch(pillarImage5);
    const pImageBlob5 = await pImageResponse5.blob();
    const pImageBuffer5 = await pImageBlob5.arrayBuffer();

    const pImageResponse6 = await fetch(pillarImage6);
    const pImageBlob6 = await pImageResponse6.blob();
    const pImageBuffer6 = await pImageBlob6.arrayBuffer();

    const pImageResponse8 = await fetch(pillarImage8);
    const pImageBlob8 = await pImageResponse8.blob();
    const pImageBuffer8 = await pImageBlob8.arrayBuffer();

    const pImageResponse12 = await fetch(pillarImage12);
    const pImageBlob12 = await pImageResponse12.blob();
    const pImageBuffer12 = await pImageBlob12.arrayBuffer();

    const pImageResponse15 = await fetch(pillarImage15);
    const pImageBlob15 = await pImageResponse15.blob();
    const pImageBuffer15 = await pImageBlob15.arrayBuffer();

    const pImageResponse18_1 = await fetch(pillarImage18_1);
    const pImageBlob18_1 = await pImageResponse18_1.blob();
    const pImageBuffer18_1 = await pImageBlob18_1.arrayBuffer();

    const pImageResponse18_2 = await fetch(pillarImage18_2);
    const pImageBlob18_2 = await pImageResponse18_2.blob();
    const pImageBuffer18_2 = await pImageBlob18_2.arrayBuffer();

    const pImagePhone = await fetch(phoneIcon);
    const pImagePhoneBlob = await pImagePhone.blob();
    const pImagePhoneBuffer = await pImagePhoneBlob.arrayBuffer();

    const pImageEmail = await fetch(emailIcon);
    const pImageEmailBlob = await pImageEmail.blob();
    const pImageEmailBuffer = await pImageEmailBlob.arrayBuffer();

    const pImageWebsite = await fetch(webIcon);
    const pImageWebsiteBlob = await pImageWebsite.blob();
    const pImageWebsiteBuffer = await pImageWebsiteBlob.arrayBuffer();

    // Create a header
    const header = new Header({
      children: [
        watermarkParagraph,
        // new Paragraph({
        //   children: [
        //     new TextRun({
        //       // text: `PILLAR SECURITY – CONFIDENTIAL PROPOSAL FOR ${siteName} (SERVICE – ${s_TotalHoursYear} HOURS) – DATE`,
        //       text: `PILLAR SECURITY – CONFIDENTIAL PROPOSAL FOR ${siteName.toUpperCase()} (SERVICE – ${totalhours_reg_week} HOURS) – ${getDateTime(
        //         date_Created,
        //         "MMM DD, YYYY"
        //       )}`,
        //       bold: true,
        //       font: "Helvetica",
        //       size: 17, // 12pt
        //     }),
        //   ],
        //   spacing: { after: 200 },
        //   alignment: "center",
        // }),
      ],
    });

    // Create a footer
    const footer = new Footer({
      children: [
        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    createParagraph(
                      "21 Four Seasons Place, Toronto, ON M9B 0A6",
                      { size: 14, alignment: AlignmentType.CENTER }
                    ),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 35,
                    type: WidthType.PERCENTAGE,
                  },
                }),
                new TableCell({
                  children: [
                    createParagraphImage("416-777-1812", pImagePhoneBuffer, {
                      width: 16,
                      height: 16,
                      size: 14,
                      alignment: AlignmentType.LEFT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 15,
                    type: WidthType.PERCENTAGE,
                  },
                }),
                new TableCell({
                  children: [
                    createParagraphImage(
                      "www.pillarsecurity.com",
                      pImageWebsiteBuffer,
                      {
                        width: 16,
                        height: 16,
                        size: 14,
                        alignment: AlignmentType.LEFT,
                      }
                    ),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                }),
                new TableCell({
                  children: [
                    createParagraphImage(
                      "info@pillarsecurity.com",
                      pImageEmailBuffer,
                      {
                        width: 16,
                        height: 16,
                        size: 14,
                        alignment: AlignmentType.LEFT,
                      }
                    ),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          children: [PageNumber.CURRENT],
                          font: "Helvetica",
                          size: 20,
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                  width: {
                    size: 10,
                    type: WidthType.PERCENTAGE,
                  },
                }),
              ],
            }),
          ],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE, // Make the table take the full width of the page
          },
          borders: {
            top: { style: BorderStyle.NONE },
            bottom: { style: BorderStyle.NONE },
            left: { style: BorderStyle.NONE },
            right: { style: BorderStyle.NONE },
            insideHorizontal: { style: BorderStyle.NONE },
            insideVertical: { style: BorderStyle.NONE },
          },
        }),
      ],
    });

    const doc = new Document({
      sections: [
        // Page 1
        {
          properties: {
            page: {
              size: {
                width: 12240,
                height: 15840,
                orientation: PageOrientation.PORTRAIT,
              },
              margin: { top: 0, right: 0, bottom: 0, left: 0 },
              borders: sectionBorders,
            },
          },
          children: [
            new Paragraph({
              children: [
                new ImageRun({
                  data: bgArrayBuffer,
                  transformation: {
                    width: 751,
                    height: 991,
                  },
                }),
              ],
              alignment: "center",
              spacing: {
                before: 500, // Add space before the background image
              },
            }),

            new Paragraph({
              children: [
                new ImageRun({
                  data: overlayArrayBuffer,
                  transformation: {
                    width: 250,
                    height: 133,
                  },
                  floating: {
                    allowOverlap: true,
                    zIndex: 0,
                    horizontalPosition: {
                      offset: 4.5 * 1014400,
                    },
                    verticalPosition: {
                      offset: 1014400,
                    },
                  },
                }),
              ],
              alignment: "center",
            }),
          ],
        },
        // Page 2
        {
          headers: {
            default: header,
          },
          footers: {
            default: footer,
          },
          properties: {
            page: commonPageProperties,
          },
          children: [
            createParagraph("", {
              spacingbefore: 2000,
            }),
            createImage(pImageBuffer2, { width: 650, height: 433 }),
            createParagraph("CONFIDENTIAL PROPOSAL", {
              size: 44,
              bold: true,
              spacingbefore: 2000,
              spacingafter: 500,
              alignment: AlignmentType.CENTER,
            }),
            createParagraph("PILLAR SECURITY", {
              size: 44,
              bold: true,
              alignment: AlignmentType.CENTER,
            }),
          ],
        },
        // Page 3
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createParagraph(
              "To the attention of the Property Manager and Board of Directors"
            ),
            createParagraph(`${siteName} – ${corpNo}`),
            createParagraph(`${address}`),
            createParagraph(`${city} ${province} ${postalCode}`, {
              spacingafter: 200,
            }),
            createParagraph("Dear Property Manager and Board of Directors,", {
              spacingafter: 200,
            }),
            createParagraph(
              `Thank you very much for the opportunity to introduce Pillar Security as a potential concierge and security service provider for the residents of ${siteName}.`,
              { spacingafter: 200 }
            ),
            createParagraph(
              "Pillar cares about the challenges and needs of our clients and will always place their needs first. We win together, never alone. From the moment contact is made, through the proposal process and guard selection, we pledge accountability to our clients with the goal of exceeding their expectations. Our strength is your security. Guard and concierge service are the most effective thing you can do to ensure the security of your residents and the building. As your security partner, Pillar Security can oversee every step in the security process by implementing standard tours tracked by proxy points utilized by the guards, as well as 24/7 monitoring by our own internal monitoring station.",
              { spacingafter: 200 }
            ),
            createParagraph(
              `For twenty-five years, Pillar Security has specialized in concierge and security management of residential condominiums. With a substantial portfolio across the GTA, we bring a wealth of experience to the residents of ${siteName}. We pride ourselves on being a caring and innovative company and have developed robust relationships with every one of our customers.`,
              { spacingafter: 200 }
            ),
            createParagraph(
              "Being security professionals, we understand the importance of motivated and well-trained personnel and as such, the pay rate for Head Concierge is kept at a premium for purposes of retention and in the belief that they are of paramount importance to the improvements in our service. We work closely with every member of our team to ensure consistency in the services we offer.",
              { spacingafter: 200 }
            ),
            createParagraph(
              `During the initial transition period, the Operations Manager will attend your site three times a week to ensure, evaluate and create tailored standing orders in accordance with the specific needs of the site. Inspection of the security guards will be conducted by the mobile supervisor three times each week, including nights and weekends.`,
              { spacingafter: 200 }
            ),
            createParagraph(
              `Regular meetings with the Property Manager may be conducted to discuss issues, site improvements and evaluation of the proposed phases to assess the level of achievement during the process of implementation. We, at Pillar Security, understand the scope of this project and are ready to commit considerable resources and time reviewing all pertinent procedures to ensure that security staff, property management, maintenance staff and residents all have a clear understanding of the current procedural guidelines; all to create a safe and pleasant community environment for the residents of ${siteName}.`,
              { spacingafter: 200 }
            ),
            createParagraph("Sincerely,", { spacingafter: 200 }),
            createParagraph("Yathusan Rajasekaram"),
            createParagraph("Vice President, Operations"),
            createParagraph("Pillar Security"),
            createParagraph("P: 437.288.4091"),
            createParagraph("E: yrajasekaram@pillarsecurity.com"),
          ],
        },

        // Page 4
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createHeading("1.0 Executive Summary", { bold: true }),

            createParagraph("1.1 Overview", {
              bold: true,
              spacingafter: 200,
            }),
            createParagraph(
              "The ability to provide a superior level of service at competitive prices is a constant struggle in any industry. The challenge becomes even more difficult when you must combine security and emergency response with concierge and hospitality services. Pillar Security has been successfully navigating these challenges for over twenty-two years. We are committed to providing a secure and well-managed community environment for all residents. Some aspects of our service that set us apart include:",
              { spacingafter: 200 }
            ),
            createTickBullet(
              "Software-based booking system facilitating the smooth reservation and inspection of all facilities including those which are shared"
            ),
            createTickBullet(
              "Hands-on management style predicated upon a high level of communication between residents, property management and the Board of Directors"
            ),
            createTickBullet("Parking Enforcement, MLEO-Licensed"),
            createTickBullet(
              "Established security company with a high level of expertise in residential condominium security and concierge services."
            ),
            createTickBullet(
              "Highly trained and licensed security staff with a clear understanding of emergency and incident response procedures"
            ),
            createTickBullet("The Pillar Accreditation Training System (PATS)"),
            createTickBullet(
              "Proprietary “Aquarius” communication and security software used as a tool to facilitate the efficient and effective management of all services."
            ),
            createTickBullet(
              "Weekly random site and guard audits providing a metric for continuous improvement."
            ),

            ///IMAGE
            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer4_1, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 300,
                    height: 160,
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 4.25 * 1000000,
                    },
                    verticalPosition: {
                      offset: 1 * 1000000,
                    },
                    wrap: {
                      type: TextWrappingType.SQUARE,
                      side: TextWrappingSide.LEFT,
                    },
                    margins: {
                      top: 20,
                      bottom: 20,
                      left: 100000,
                      right: 100000,
                    },
                  },
                }),
              ],
              alignment: AlignmentType.RIGHT, // Align image to the right
            }),

            createParagraph("1.2 Security and Concierge Services", {
              bold: true,
              spacingafter: 150,
            }),
            createParagraph(
              "Pillar Security offers a unique level of service that focuses on superior communication with residents, board members and property management. A seamless integration of the following services is the foundation upon which our operation functions:",
              { spacingafter: 150 }
            ),
            createParagraph(
              "Security Services – Security patrols and a sophisticated, software-based system for security logs. A copy of Incident Reports is submitted to Property Management daily.",
              { spacingafter: 150 }
            ),
            createParagraph(
              "Safety/Emergency Response – Timely and coordinated responses to fire alarms, suite and mechanical alarms, medical and police situations.",
              { spacingafter: 150 }
            ),
            createParagraph(
              "Concierge Services – Coordination of all common facilities such as parking and elevators.",
              { spacingafter: 150 }
            ),

            ///IMAGE
            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer4_2, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 250,
                    height: 244,
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 4.7 * 1000000,
                    },
                    verticalPosition: {
                      offset: 4.8 * 1000000,
                    },
                    wrap: {
                      type: TextWrappingType.SQUARE,
                      side: TextWrappingSide.LEFT,
                    },
                    margins: {
                      top: 20,
                      bottom: 2 * 100000,
                      left: 100000,
                      right: 100000,
                    },
                  },
                }),
              ],
              alignment: AlignmentType.RIGHT, // Align image to the right
            }),

            createParagraph("1.3 Management Services", {
              bold: true,
              spacingafter: 150,
            }),
            createParagraph(
              "We distinguish ourselves from our competition through the ability of our management team to coordinate efforts by fully understanding the daily logistics of each building we service. Our managers and staff take pride in being able to answer your questions and respond to any given situation with an intelligent, informed approach.",
              { spacingafter: 150 }
            ),
            createParagraph("Our management services include:", {
              spacingafter: 150,
            }),
            createTickBullet(
              "Regular visits to each facility by a senior manager"
            ),
            createTickBullet(
              "The only security company to offer senior management access 24 hours a day and 7 days a week."
            ),
            createTickBullet(
              "Participation at Board and Committee meetings upon request"
            ),
            createTickBullet(
              "24-hour off-site monitoring of emergency and mechanical situations by our Dispatch Department"
            ),
            createTickBullet(
              "Comprehensive training of all staff by senior management"
            ),
          ],
        },

        // Page 5
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createParagraph(
              "1.4 Communication and Security Software – “Aquarius”",
              { bold: true, spacingafter: 200 }
            ),
            createParagraph(
              "The effective communication of accurate information is critical to the security/concierge industry. It is imperative particularly in emergency response situations, to follow designated procedural guidelines. That is why we use Aquarius, a tool that facilitates communication by providing a secure and searchable platform for recording and accessing information. In addition to the capability of accessing this information at critical times, Aquarius virtually eliminates the need for handwritten logs. All information pertaining to parking permits, vehicle ticketing, facility bookings, packages and maintenance inquiries are entered into the system and is easily searchable by authorized individuals. Aquarius promotes an immediate response to any situation by providing faster access to accurate and up to date information. Pillar has a yearly group subscription with Aquarius, and therefore, offers this software free within your contract.",
              { spacingafter: 200 }
            ),

            createParagraph("1.5 Integration Procedures", {
              bold: true,
              spacingafter: 200,
            }),
            createParagraph(
              "The ability of a company to efficiently integrate its services in a functional manner depends largely on having set criteria and guidelines for carrying out the process. Prior to commissioning, we will incorporate site-specific elements into the Site Manual template and update procedural guidelines as they develop. This will be the first step in formulating the implementation of a custom service plan for your facility. Walk-through, meeting with property management, and the Board will be conducted as part of the integration process.",
              { spacingafter: 200 }
            ),

            createParagraph("1.6 Training Systems and Personnel", {
              bold: true,
              spacingafter: 200,
            }),
            createParagraph(
              "Training of concierge and security personnel is of primary importance to the consistency of the services we offer. Over the years, we have developed a formal training program named Pillar Accreditation and Training System or PATS. PATS is built upon demonstrated ability within a matrix which provides a metric using random auditing to identify deficiencies and competencies within our staff. Any deficiency is followed with corrective action, while compliance is rewarded with recognition and reward applied using Performance Management Techniques. The training program focuses on, but is not limited to, the following service areas:",
              { spacingafter: 200 }
            ),

            createTickBullet("Fire, Emergency, and Incident Response"),
            createTickBullet("Premises Patrol"),
            createTickBullet("Software Systems Training"),
            createTickBullet("Regular Audits and Corrective Action", {
              spacingafter: 200,
            }),
            createTickBullet("Customer Satisfaction"),

            createParagraph(
              "All security personnel are trained by senior management and are licensed in compliance with the provisions of the Private Investigators and Security Guard Act (“PISGA”).",
              { spacingbefore: 200, spacingafter: 200 }
            ),

            createImage(pImageBuffer5, { width: 500, height: 297 }),
          ],
        },

        // Page 6
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createHeading(
              "2.0 Condominium and Residential Security Experience",
              { bold: true }
            ),
            createParagraph(
              "Pillar Security has established itself as a trusted and reliable security provider, currently serving an extensive client base comprising more than 45 residential buildings and condominiums. Our commitment to excellence and dedication to meeting the unique security needs of each client have contributed to our strong reputation in the industry.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "We understand that every property has its own set of security challenges, and we are well-equipped to address them effectively. With our vast experience in the field, we have gained valuable insights into the evolving nature of security threats and the importance of tailored solutions for residential communities. Our team of highly qualified security professionals possesses exceptional customer service skills, ensuring a balance between rule enforcement and a friendly, welcoming environment.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "With a proud history spanning many years, Pillar Security has been serving clients throughout the GTA and across Ontario, providing exceptional security and customer service. Our proven processes, comprehensive procedures, and tailored training programs exemplify our dedication to striking the perfect equilibrium between trust, respect, and outstanding customer service, while ensuring the application of effective security measures.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "Pillar Security proudly provides security services to more than 45 residential clients in the GTA including:",
              { spacingafter: 200 }
            ),

            createImage(pImageBuffer6, { width: 500, height: 190 }),

            createParagraph("", {
              spacingafter: 300,
            }),

            createHeading("3.0 Security and Concierge Service", {
              bold: true,
            }),
            createParagraph("3.1 Security Services", {
              bold: true,
              spacingbefore: 150,
              spacingafter: 200,
            }),
            createTickBullet(
              "Monitoring security cameras and ensuring video equipment is functional."
            ),
            createTickBullet(
              "Facilitating entry of authorized visitors and trades people"
            ),
            createTickBullet("Enforcing parking rules and regulations"),
            createTickBullet(
              "Responding to telephone inquiries by residents and visitors"
            ),
            createHeadParargraph(
              "Security Patrols – ",
              "Given the limitations of most surveillance equipment, it is imperative that security patrols be routinely executed. These patrols ensure that no entry points have been compromised while monitoring the operational elements of a building. Security patrols are executed once an hour during the evening and night shifts and usually take approximately 15 minutes. During the time the guard is away from the desk, our Dispatch Department will remotely monitor the area until the guard returns from patrol. Every element of a building will be examined during a security shift. Security patrols involve the following procedures:",
              { spacingbefore: 200, spacingafter: 200 }
            ),

            createTickBullet(
              "Inspect all entry points to ensure doors are locked and perimeter is clear."
            ),
            createTickBullet(
              "Check mechanical/electrical rooms for leaks, abnormal pressure readings or unusual odours."
            ),
            createTickBullet(
              "Inspect parking areas (visitors and residents) to ensure parking compliance and check for safety hazards."
            ),
            createTickBullet(
              "Patrol all stairwells, floor hallways, common areas and facilities to check for signs of forced entry, vandalism, garbage, smoking infractions, or areas in disrepair."
            ),
          ],
        },

        // Page 7
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createHeadParargraph(
              "Security Logs – ",
              "The accurate communication of information to relief staff, senior managers and property management is integral to the daily functionality of a building. Traditional handwritten security logs are at times illegible, cumbersome to search and in many cases discourage investigations. Depending on the parameters of an incident, the appropriate individuals are informed automatically via email, with daily, weekly, and monthly reports. The checks and balances of the process ensure adherence with established practices and procedures.",
              { spacingbefore: 200, spacingafter: 200 }
            ),
            createParagraph("3.2 Safety/Emergency Response", { bold: true }),
            createParagraph(
              "A confident and appropriate response to safety and emergency situations can be the difference between a positive or negative outcome. It is vital for security personnel to have readily available and detailed procedures to follow in an emergency response situation. This combined with extensive training for all security staff in emergency and incident response sets Pillar Security apart from its competition. Some of the more prevalent incidences and the responses we consider to be appropriate include:",
              { spacingafter: 200 }
            ),
            createTickHeadParargraph(
              "Fire Alarms – ",
              "Keep residents informed throughout the event via P.A. announcements, coordinate monitoring company and Fire Services response, ensure all fire routes and emergency exits are clear of obstacles, reset alarm panels, MAG locks and elevators once situation is reconciled, and inform site supervisor and property manager accordingly.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Suite and Mechanical Alarms – ",
              "Investigate and respond accordingly. Inform site supervisor and/or property manager if necessary. Notifying the property management may not always be necessary. For instance, a small leak within a resident suite may only require a phone call to the owner and a recommendation for a plumber. However, should a major heating or air conditioning situation arise, property management must be contacted. Security’s familiarity of the building and the understanding of the varying degrees of response assist in creating a well-managed environment.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Medical Emergencies – ",
              "Coordinate all emergency response procedures. This would include immediately contacting emergency response services and assisting emergency personnel upon arrival.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createParagraph("3.3 Customer Service plus Concierge Services", {
              bold: true,
              spacingafter: 150,
            }),
            createParagraph(
              "Pillar Security approaches the provision of its services from a rather unique perspective. We feel it is equally important to provide superior concierge services, as it is to maintain a high level of security. This can only be achieved by having an intimate understanding of the resident’s needs. Through a meticulous survey of residents, owners, board members and property management, we will create dedicated post orders for your site. Once the post orders have been created, the appropriate information is inputted in the software and a personal concierge plan is put in place. Some of the standard services offered include:",
              { spacingafter: 200 }
            ),

            createParagraph("Module #1 – Customer Service", {
              bold: true,
              spacingbefore: 200,
              spacingafter: 150,
            }),
            createTickBullet(
              "Coordinating and administering visitor parking passes."
            ),
            createTickBullet(
              "Facilitating the receipt and delivery of packages."
            ),
            createTickBullet(
              "Coordinating the booking and use of common areas, shared facilities and moving elevators."
            ),
            createTickBullet(
              "Coordinate the access of trades people, real estate agents and visitors."
            ),
            createTickBullet(
              "Welcome visitors in a friendly and refined manner."
            ),
            createTickBullet(
              "Anticipate the needs and desires of residents and their visitors."
            ),
            createTickBullet("Telephone and in person etiquette."),
            createParagraph("Module #2 – Concierge Service", {
              bold: true,
              spacingbefore: 200,
              spacingafter: 150,
            }),
            createTickBullet(
              "Manage deliveries by ensuring they are completed in a timely fashion."
            ),
            createTickBullet(
              "Perform or assist with any other duties that will facilitate a comfortable and pleasant living experience for the building’s residents."
            ),
            createTickBullet("Routine Concierge Requests."),
            createTickBullet("Assisting the elderly and disabled."),
          ],
        },

        // Page 8
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createParagraph("3.4 Mobile Support", {
              bold: true,
              spacingafter: 150,
            }),
            createParagraph(
              "Pillar Security takes great pride in providing comprehensive security solutions to residential buildings and condominiums. As part of our commitment to delivering exceptional service, we offer a dedicated Mobile Support division to enhance our on-site security operations and provide additional assistance when needed.",
              { spacingafter: 150 }
            ),
            createParagraph(
              "Our Mobile Supervisors are available 24/7, ensuring round-the-clock support for emergency situations, random and scheduled site inspections, ongoing training, and additional supervision. This invaluable service is designed to offer our clients an extra layer of security and peace of mind.",
              { spacingafter: 150 }
            ),

            ///IMAGE
            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer8, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 300,
                    height: 162,
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 4.25 * 1000000,
                    },
                    verticalPosition: {
                      offset: 0.9 * 1000000,
                    },
                    wrap: {
                      type: TextWrappingType.SQUARE,
                      side: TextWrappingSide.LEFT,
                    },
                    margins: {
                      top: 20,
                      bottom: 20,
                      left: 100000,
                      right: 100000,
                    },
                  },
                }),
              ],
              alignment: AlignmentType.RIGHT, // Align image to the right
            }),

            createParagraph(
              "Mobile Supervisors at a concierge security company play a crucial role in ensuring the smooth operation and effectiveness of security services. Their duties typically include:",
              { spacingafter: 150 }
            ),

            createTickHeadParargraph(
              "Site Inspections: ",
              "Mobile Supervisors conduct regular and unannounced site inspections to monitor the performance of on-site security guards. They assess guard deportment, adherence to protocols, and overall security measures in place.",
              {
                spacingbefore: 200,
                spacingafter: 100,
              }
            ),
            createTickHeadParargraph(
              "Emergency Response: ",
              "In emergency situations, Mobile Supervisors are responsible for promptly coordinating and responding to incidents at various client locations. They provide guidance and support to on-site security guards, ensuring a swift and efficient resolution.",
              {
                spacingbefore: 100,
                spacingafter: 100,
              }
            ),
            createTickHeadParargraph(
              "Training and Development: ",
              "Mobile Supervisors play a key role in training and developing the skills of security guards. They provide ongoing coaching, identify areas for improvement, and conduct refresher training sessions to maintain high standards of performance.",
              {
                spacingbefore: 100,
                spacingafter: 100,
              }
            ),
            createTickHeadParargraph(
              "Communication and Reporting: ",
              "Mobile Supervisors maintain regular communication with on-site security guards, sharing information, updates, and instructions as needed. They prepare detailed reports on inspections, incidents, and guard performance for internal records and client review.",
              {
                spacingbefore: 100,
                spacingafter: 100,
              }
            ),

            createTickHeadParargraph(
              "Quality Assurance: ",
              "Mobile Supervisors play a crucial role in upholding the highest standards of security operations. They oversee the implementation of security protocols and procedures, ensuring compliance and effectiveness. By identifying areas for improvement, they suggest enhancements to security measures and address any operational deficiencies promptly.",
              {
                spacingbefore: 100,
                spacingafter: 200,
              }
            ),

            createHeading("4.0 Management Services", { bold: true }),
            createParagraph(
              "The public impression of the quality of service a company provides is a direct reflection of that company’s management of its workforce and the environment in which it functions. Pillar Security offers an urbane approach to its services by being well versed with all corporation rules and regulations and when necessary, reminding residents of their responsibilities. Over time, this will promote enhanced community living by creating a secure and enjoyable environment for all. This can only be achieved by taking a hands-on management approach whereby a senior supervisor, specifically assigned to oversee your building, attends to your site needs on a regular basis. To address any emergency situations this supervisor is also available and on-call to property management 24 hours a day, 7 days a week. Hands-on management is also about knowing your building and becoming familiar with all existing mechanical procedures allowing us to maintain existing functional systems while improving procedures which are lacking in direction. An understanding such as this gives us an advantage during the development of our training procedures whereby specific operational elements of a site can be highlighted. Security personnel will have the confidence to respond accordingly to most severe situations.",
              { spacingbefore: 200, spacingafter: 200 }
            ),
            createParagraph(
              "Our management team will work closely with you to customize the security/ hospitality services to precisely fit your needs. We will conduct a detailed questionnaire with the Property Manager and follow up with a full report to the Board of Directors. Areas of concern will be highlighted, and suggestions will be made to help the Board in its decision process. Should our presence be requested at Board or Committee meetings to address any of these needs, we will gladly make ourselves available. A complete post-order manual covering all security and hospitality functions will be created and reviewed on a regular basis ensuring adherence to the process.",
              { spacingafter: 200 }
            ),
          ],
        },

        // Page 9
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createParagraph(
              "We work closely with our staff, acting as mentors as well as managers and if requested conduct weekly on-site meetings to address any outstanding issues. We also ensure that the Site Supervisor will effectively communicate any concerns requiring immediate attention.",
              { spacingafter: 200 }
            ),
            createHeading("5.0 Site-Specifics", { bold: true }),
            createParagraph(
              "The ability to address some of the unique functional elements of a building can distinguish one service provider from another. Some elements receiving particular attention include:",
              { spacingbefore: 200, spacingafter: 200 }
            ),

            createTickHeadParargraph(
              "Communications – ",
              "Effective communication between staff and property management is paramount.",
              {
                spacingbefore: 200,
                spacingafter: 100,
              }
            ),
            createTickHeadParargraph(
              "Building Access – ",
              "A full audit of your FOBs, access points and facilities. Monitor and record who is permitted access to the buildings to ensure that the safety and security of the residents is not compromised. Facilitate communication between trade workers, real estate, visitors, and residents in common elements to ensure an adequate and seamless transition.",
              {
                spacingbefore: 100,
                spacingafter: 100,
              }
            ),
            createTickHeadParargraph(
              "Patrols – ",
              "Properly staggering and coordinating patrols of a premises helps ensure a secure and pleasant environment for all. Logging these patrols in a searchable format not only ensures that the patrols are being carried out in a timely manner, but also triggers appropriate responses to situations that may be ongoing.",
              {
                spacingbefore: 100,
                spacingafter: 100,
              }
            ),
            createTickHeadParargraph(
              "Monitoring – ",
              "Observation of visitors and residents in common elements to ensure adherence with the Corporation’s rules and policies. Pillar Security prides itself on being able to assess the specific needs of a particular building and offer suggestions for creating a safe and harmonious environment to attain the high standard and values. It is our policy to conduct a full assessment of the existing systems and propose suggestions to the Board to provide a welcoming atmosphere for the residents.",
              {
                spacingbefore: 100,
                spacingafter: 100,
              }
            ),
            createTickHeadParargraph(
              "Emergency Response - ",
              "This is by far the most important component of our security training process. We emphasize all elements related to fire emergency response and personal emergencies. Our employees are fully trained in fire panel indicators and enunciators, monitoring company procedures, maintenance company responsibilities and sprinkler room valve operations. We meet with the operations manager of the building to fully understand how these systems function and incorporate them into our training procedures.",
              { spacingbefore: 100, spacingafter: 200 }
            ),

            createHeading(
              "6.0 Communication and Security Software – “Aquarius”",
              { bold: true }
            ),
            createParagraph(
              "Manually written reports have been the conventional method used as a means of communication in the security and concierge industry. Some companies have tried to “pass on” books or use memorandums to further enhance communication. This slow and inefficient model of operation can lead to costly misunderstandings and inaccuracies.",
              { spacingbefore: 200, spacingafter: 200 }
            ),
            createParagraph(
              "Our proprietary Aquarius software eliminates this confusion and creates an efficient and accessible communication system. With its powerful search engine, MyCondoLink gives you the ability to search for records on a fully integrated, secure database. It is designed to facilitate communication between residents, staff, property management and the board. It monitors vital information and provides detailed procedures to follow in emergency situations.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "Some of the features and benefits of the system include:",
              { spacingafter: 200 }
            ),

            createTickBullet(
              "Searchable security manual for safety and emergency response"
            ),
            createTickBullet(
              "Automated logbooks with custom formats for ease of entry – no manual notes"
            ),
            createTickBullet(
              "Internal message centre – provides instant messaging and allows for scheduled reminders and bulletins."
            ),
            createTickBullet(
              "Centralized, secure database for all information – authorized individuals can access information off-site via the internet."
            ),
            createTickBullet(
              "Dedicated managing centres – includes modules for visitor parking, package receipt and delivery, incident reporting, facility bookings and suite management."
            ),
            createTickBullet(
              "Internal IT controls governing appropriate levels of access to system (i.e. resident vs. staff vs. management)"
            ),
          ],
        },

        // Page 10
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createHeading("7.0 Human Resources", { bold: true }),
            createParagraph("7.1 Recruitment Policies", {
              bold: true,
              spacingbefore: 100,
              spacingafter: 200,
            }),
            createParagraph(
              "At Pillar Security, we place great importance on ensuring that our team members meet the necessary qualifications and standards. Therefore, our dedicated Human Resources Department meticulously evaluates every resume received, thoroughly assessing the experience and educational background of each applicant.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "For consideration, candidates must meet these minimum requirements:",
              { spacingafter: 200 }
            ),

            createTickBullet(
              "Applicants must meet the minimum education requirement of holding an Ontario Grade 12 diploma or its equivalent."
            ),
            createTickBullet(
              "Proof of Canadian citizenship, landed immigrant status, or possessing a valid work permit/visa."
            ),
            createTickBullet("A clean criminal record."),
            createTickBullet(
              "Demonstrating exceptional personal hygiene, maintaining a professional appearance, and availability."
            ),
            createTickBullet(
              "At Pillar Security, we prioritize the safety and well-being of our team members, which is why we require all employees to obtain First Aid and CPR certification."
            ),
            createTickBullet(
              "All applicants must pass the fundamental security guard ministry test and possess a valid security license issued by the Ontario Ministry of Community Safety & Correctional Services."
            ),
            createTickBullet(
              "Candidates must demonstrate physical fitness to endure prolonged periods of sitting, standing, and walking."
            ),
            createTickBullet(
              "Pillar Security employees are required to possess excellent English language proficiency, both spoken and written."
            ),
            createParagraph("7.2 Pre-Employment Process", {
              bold: true,
              spacingbefore: 200,
              spacingafter: 200,
            }),
            createParagraph(
              "At Pillar Security, we have established an effective pre-employment process that ensures we attract and select qualified candidates to join our team of dedicated concierge security guards. To reach potential candidates, we utilize job search platforms like Indeed and walk-ins. We actively engage with Indeed to post our job openings, track metrics, and analyse resumes. Additionally, we leverage our strong network of client referrals, employee referrals, and industry contacts to attract qualified individuals who are passionate about security.",
              { spacingafter: 200 }
            ),
            createParagraph("7.3 Recruiting and Screening Process", {
              bold: true,
              spacingafter: 200,
            }),
            createParagraph(
              "By implementing a targeted recruiting and screening process, Pillar Security ensures that we attract exceptional candidates who are committed to providing top-notch concierge security services to our valued clients. Here's how we go about it:",
              { spacingafter: 200 }
            ),
            createTickHeadParargraph(
              "Internal Talent Promotion: ",
              "As part of our commitment to career development and growth, we prioritize internal promotions. We regularly assess the performance and potential of our existing security personnel and supervisors, offering them opportunities for advancement and increased compensation. This strategy allows us to recognize and reward our employees' dedication and encourages them to further develop their skills.",
              {
                spacingbefore: 200,
                spacingafter: 100,
              }
            ),
            createTickHeadParargraph(
              "Transparent Job Postings: ",
              "To ensure transparency and provide equal opportunities, we make all vacancy and new site postings easily accessible to our security personnel. We consider suitability and qualifications as key factors in decision-making processes for promotions or transfers. By encouraging our security personnel and supervisors to communicate their career aspirations, update their resumes, and acquire new skills, we empower them to pursue growth within the organization.",
              {
                spacingbefore: 100,
                spacingafter: 100,
              }
            ),
            createTickHeadParargraph(
              "Training and Development Opportunities: ",
              "We recognize our responsibility to provide comprehensive training and development opportunities to those who demonstrate the desire and potential for growth. By offering skill-enhancing programs, ongoing education, and challenging assignments, we foster a culture of continuous learning and improvement. This approach not only supports career advancement but also contributes to reduced turnover.",
              {
                spacingbefore: 100,
                spacingafter: 100,
              }
            ),
            createParagraph(
              "Our focus on internal growth and professional development further strengthens our team and contributes to the overall success of our organization.",
              { spacingafter: 200 }
            ),
          ],
        },

        // Page 11
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createParagraph("7.4 Interview and Selection Process", {
              bold: true,
              spacingafter: 200,
            }),
            createParagraph(
              "At Pillar Security, we have developed a comprehensive interview and selection process to ensure we identify the most qualified candidates who align with our company values and possess the necessary skills. Here's an overview of our approach:",
              { spacingafter: 200 }
            ),
            createTickHeadParargraph(
              "Skills and Knowledge Assessment: ",
              "Our recruitment team follows a thorough skills and knowledge-based training program to assess the fitness of candidates. This process allows us to evaluate each candidate's capabilities and suitability for the position they are applying for.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Behavioural Interviewing Principles: ",
              "Candidates who pass the initial screening stage are invited to participate in a behavioural interview. We ask a series of carefully crafted situational questions that provide insights into their past behaviours and help predict their future performance. Our interviewers assess their responses, work history, professionalism, personal suitability, and relevant experience. This approach enables us to make informed decisions regarding their fit for the role and potential for career progression within Pillar Security.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Recruitment Phase Guidance: ",
              "Throughout the interview and selection process, we provide candidates with guidance on potential career streams within Pillar Security. We believe in supporting their growth and helping them identify future opportunities for career advancement. By offering guidance and feedback, we ensure that candidates have a clear understanding of our organization's structure and their potential path within it.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),

            createParagraph("7.5 Hiring, Onboarding and Orientation", {
              bold: true,
              spacingafter: 200,
            }),
            createParagraph(
              "Once selected, security personnel at Pillar Security can commence their employment after meeting certain requirements. These requirements include providing a valid Security Guard or Private Investigator License issued by the appropriate provincial regulatory licensing agency and successful completion of mandatory training at Pillar Security Head Office. Any concerns regarding",
              { spacingafter: 200 }
            ),

            createParagraph("7.6 Retention and Recognition", {
              bold: true,
              spacingafter: 200,
            }),
            createParagraph(
              "At Pillar Security, we prioritize employee retention by offering competitive wages, benefits, and incentives to our security personnel. We understand the value of ongoing professional development and provide access to relevant training opportunities to support their career growth.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "To foster a positive work environment and recognize exceptional performance, we provide bonuses to the employees who consistently go above and beyond. Additionally, we publish the names of site supervisors and site guards of the month on our monthly newsletter to show appreciation for their continuous hard-work and contribution towards the success of the company. By creating a workplace that respects and empowers our team members, offering ongoing professional development opportunities, and implementing retention strategies, we ensure the success of our security program and deliver exceptional service to our valued clients.",
              { spacingafter: 200 }
            ),
          ],
        },

        // Page 12
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createParagraph("7.7 Training Systems and Personnel", {
              bold: true,
              spacingafter: 200,
            }),
            createParagraph(
              "Consistent and systematic training is critical to providing a timely and appropriate response to any emergency. The safety and security of all residents and visitors hinges upon having knowledgeable, confident staff in place to respond accordingly. We strive to provide the highest standard of training possible. We believe the people we put in place are ambassadors that represent not only the interests of Pillar Security, but those of the corporation. We are diligent about assessing the strengths and weaknesses of everyone we hire and try to understand their needs. Our industry is based on shift rotation and every shift has its own nature. It is the responsibility of our management team to understand these needs and incorporate them into the shift structure.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "Once this training has been completed, it is the responsibility of our Operations Team and the Dispatch Department to ensure adherence with all safety and security procedures. This is achieved through regular site visits as well as having Dispatch personnel available 24/7 to acknowledge a situation and assist the guards accordingly.",
              { spacingafter: 200 }
            ),

            ///IMAGE
            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer12, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 300,
                    height: 313,
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 4.25 * 1000000,
                    },
                    verticalPosition: {
                      offset: 1 * 1000000,
                    },
                    wrap: {
                      type: TextWrappingType.SQUARE,
                      side: TextWrappingSide.LEFT,
                    },
                    margins: {
                      top: 20,
                      bottom: 20,
                      left: 100000,
                      right: 100000,
                    },
                  },
                }),
              ],
              alignment: AlignmentType.RIGHT, // Align image to the right
            }),

            createParagraph(
              "Our training systems have been developed and refined over the years to ensure our staff respond in a diligent, and respectful manner in any given situation. All our training is conducted by a senior supervisor and focuses on the following five key service areas:",
              { spacingafter: 200 }
            ),

            createTickHeadParargraph(
              "Fire Emergency Response – ",
              "This is by far the most important component of our security training process. We emphasize all elements related to fire emergency response. This includes fire panel indicators, monitoring company procedures, maintenance company responsibilities, and sprinkler room valve operations. We meet with the operations manager of the building to fully understand how these systems function and incorporate them into our training procedures and manuals.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Incident Response – ",
              "Specific training is provided on how to appropriately respond to incidents such as theft, flood, domestic violence, and medical situations. Detailed procedures are outlined in the site manual and post orders and are integrated within the Aquarius software system.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Premises Patrol – ",
              "Site-specific regulations are developed to ensure all key areas of the premises are systematically patrolled. Common areas patrols include all exit doors, mechanical rooms, parking garage and exterior grounds. Specific training is provided on how to assess the severity of observed situations. Patrols are executed every hour systematically covering all areas of a building.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Concierge Services – ",
              "The most important component of our hospitality service is communication with residents and guests. It is imperative to consistently communicate in a refined, professional, and helpful manner, which in turn creates an enjoyable and functional living environment without compromising the safety and security of the residents. We feel this component adds value to the property and enhances the quality of life.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Aquarius Software Systems Training – ",
              "Complete Aquarius systems training is provided to full-time and part-time personnel with an emphasis on accurate and expedient processing of information. The Aquarius system is a powerful tool, which, when used properly and efficiently, provides a level of communication between residents and management that is second to none.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),

            createHeading("8.0 Integration Procedures", { bold: true }),
            createParagraph(
              "Integrating services into a facility and instilling confidence into management and residents requires good communication and a clear understanding of expectations. To accomplish this in a smooth and functional manner, we undertake the following integration procedures:",
              { spacingafter: 200 }
            ),
          ],
        },

        // Page 13
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createTickHeadParargraph(
              "Phase 1 – ",
              "Meet with Property Management and the Board of Directors to gain an understanding of the existing rules and regulations that are governing the corporation. Become familiar with the base posts orders and incorporate site-specific elements into the procedural guidelines.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Phase 2 – ",
              "Begin implementation of the Aquarius program and input resident information. Cross check all administrative elements (i.e., parking spaces, lockers, emergency contacts etc.) to ensure an appropriate flow of information.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Phase 3 – ",
              "Pillar Security’s Human Resources Department will then begin to select the security staff for this site. This would include personnel interviews and complete screening of any potential candidates. Personnel already at the site would be given an opportunity to apply for employment with our company. Any new employee applicant that successfully passes the screening process would then begin an extensive training program on the technical and functional elements unique to this facility.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Phase 4 – ",
              "Throughout the initial start-up process, Pillar Security would address all concerns the Board of Directors and Property Management have regarding the site.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),
            createTickHeadParargraph(
              "Phase 5 – ",
              "Conduct a full assessment of the security equipment in place and identify areas of deficiency. Formulate recommendations for the Board and management to review and provide quotes for new equipment if requested.",
              {
                spacingbefore: 200,
                spacingafter: 200,
              }
            ),

            createHeading("9.0 Uniforms", { bold: true }),
            createParagraph(
              "At Pillar Security, we prioritize the safety and professionalism of our Security Guards. By ensuring that our uniforms and Security Guards are in full compliance with the Ministry of Community Safety and Correctional Services guidelines, and by tailoring each uniform to fit our employees' specific requirements, we demonstrate our dedication to professionalism, safety, and attention to detail.",
              { spacingbefore: 200, spacingafter: 200 }
            ),
            createParagraph(
              "At Pillar Security, we believe that a well-dressed and confident Security Guard is an integral part of creating a secure and welcoming environment for our clients. We understand the importance of adhering to these regulations, as they are designed to uphold the highest standards in the security industry.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "All our employees have their uniforms tailored specific to them and their duties.",
              { spacingafter: 200 }
            ),

            createTickBullet("Jacket (Black, Site Supervisors only)"),
            createTickBullet("Trouser (Grey)"),
            createTickBullet("Vests (Beige)"),
            createTickBullet("Shirt (White)"),
            createTickBullet("Tie (Pillar Custom)"),
            createTickBullet("Pillar/License ID Badge"),
            createTickBullet("Shoes (Black)"),
            createTickBullet("Outdoor Jacket (Supplied upon request)"),
            createParagraph("", { spacingafter: 200 }),
            createHeading("10.0 Employee Wage and Benefits Program", {
              bold: true,
            }),
            createParagraph(
              "Pillar Security’s dedication to excellence in human resources requires that we keep turnover to a minimum. Guards hired from sites taken over from other contractors will be paid in accordance with the provisions of the Employment Standards Act, Section 77. Pillar Security will commit to pay the guards the same or higher wages in a site take over.",
              { spacingbefore: 200, spacingafter: 200 }
            ),
          ],
        },

        // Page 14
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        createNestedTable(
                          "Security Guard \nBenefits Plan:",
                          tabledata1,
                          { height: 2500 }
                        ),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable(
                          "Benefits provided by \nPillar Security:",
                          tabledata2,
                          { height: 2500 }
                        ),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable(
                          "Statutory Payroll \nTaxes/Benefits:",
                          tabledata3,
                          { height: 2500 }
                        ),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                  ],
                }),
              ],

              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE },
                bottom: { style: BorderStyle.NONE },
                left: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
              },
              layout: "fixed",
            }),

            createParagraph("", {
              bold: true,
              spacingbefore: 100,
              spacingafter: 100,
            }),

            createHeading("11.0 Proposed Shift Structure and Billing Rates", {
              bold: true,
            }),

            createParagraph("", {
              bold: true,
              spacingbefore: 100,
              spacingafter: 100,
            }),

            dataTableParagraph,

            createParagraph(
              `${formatCurrency(
                unionAmt
              )} Additional added to total pre-tax due to Union`,
              { spacingbefore: 100, spacingafter: 100 }
            ),
            createParagraph(
              `${formatCurrency(
                vacationAmt
              )} Additional added to total pre-tax due to Vacation`,
              { spacingbefore: 100, spacingafter: 100 }
            ),

            createParagraph("Events/Extra Coverage", {
              bold: true,
              spacingafter: 200,
            }),

            dataExtraTableParagraph,
          ],
        },
        // Page 15
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createHeading("12.0 Miscellaneous Items to Note", { bold: true }),
            createTickBullet(
              "Quote includes all applicable taxes, insurance, Statutory Holidays and SunLife Benefit Package."
            ),
            createTickBullet(
              "Full Value of Aquarius software is included in the quote – fee usually charged at $2 per unit, per month.",
              { bold: true }
            ),
            createTickBullet(
              "Pillar Security Mobile Supervisors will conduct regular site visits."
            ),
            createTickBullet(
              "Pillar Security Operations Managers will conduct regular site visits."
            ),
            createTickBullet(
              "Pillar Security will conduct a comprehensive security site audit with recommendations within the first month."
            ),
            createTickBullet(
              "Site Manual will be available for review within the first month."
            ),
            createTickBullet(
              "Any additional coverage will be billed at $27.00 per hour plus HST, with a 4-hour minimum."
            ),
            createTickBullet(
              "With less than 24 hours’ notice, the corporation will be billed at 1.5 times the average regular security guard rate and at 2.5 times the average regular security guard rate during Statutory Holidays."
            ),
            createTickBullet(
              "Where, due to change in government legislation or administrative ruling, some pay rates may be affected, Pillar shall notify the Client in writing of a change to the rates and the effective date of such change 45 days prior to the change taking effect."
            ),
            createTickBullet(
              "This quote is valid for 60 days from the date of this proposal.",
              { bold: true }
            ),
            createTickBullet(
              "This pricing is subject to change upon reviewing Bill 7 and Bill 148."
            ),
            createTickBullet("Cell phone for site added at additional cost."),
            createTickBullet("Proposed annual increase of 2.5%"),
            createTickBullet(
              "Tour system includes 25 tour points; additional points can be added at extra cost."
            ),

            createParagraph("", {
              bold: true,
              spacingafter: 50,
            }),

            createHeading("13.0 Proposal Summary", { bold: true }),
            createParagraph(
              "Pillar Security is not simply a company providing a service, but a family of productive, responsible individuals working together to help people live their lives in harmony. We are proud of our achievements and are confident that we offer a superior level of service and commitment. Some aspects of our service that we feel set us apart from our competition include:",
              { spacingafter: 100 }
            ),
            createTickBullet(
              "Dedicated and knowledgeable management and staff genuinely interested in sustaining a secure and pleasant community environment."
            ),
            createTickBullet(
              "Sophisticated and educated approach to managing the daily security and concierge services of your building with access to our offsite monitoring team, offering 24/7 security."
            ),
            createTickBullet("Hands-on management style"),
            createTickBullet(
              "Established Security Company with a high level of expertise in residential condominium security and concierge services."
            ),
            createTickBullet(
              "Highly trained and licensed security staff with a clear understanding of emergency and incident response procedures"
            ),
            createTickBullet(
              "Ongoing training and testing conducted by our management team."
            ),
            createTickBullet(
              "Proprietary “Aquarius” communication and security software used as a tool to facilitate the efficient and effective management of all services."
            ),

            createParagraph("", {
              bold: true,
              spacingafter: 50,
            }),

            createImage(pImageBuffer15, { width: 480, height: 250 }),
          ],
        },

        // Page 16
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createHeading("14.0 Pillar Security Scope of Duties", {
              bold: true,
            }),

            createParagraph("", {
              bold: true,
              spacingafter: 100,
            }),

            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        createNestedTable(
                          "EMERGENCY PROCEDURES",
                          tabledata14_1,
                          { height: 7200 }
                        ),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable("ACCESS CONTROL", tabledata14_2, {
                          height: 7200,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable(
                          "CONDOMINIUM POLICIES",
                          tabledata14_3,
                          { height: 7200 }
                        ),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                  ],
                }),
              ],

              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE },
                bottom: { style: BorderStyle.NONE },
                left: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
              },
              layout: "fixed",
            }),

            createParagraph("", {
              bold: true,
              spacingafter: 100,
            }),

            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        createNestedTable("RESIDENT PARKING", tabledata14_4, {
                          height: 3700,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable("VISITOR PARKING", tabledata14_5, {
                          height: 3700,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable("PACKAGES", tabledata14_6, {
                          height: 3700,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                  ],
                }),
              ],

              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE },
                bottom: { style: BorderStyle.NONE },
                left: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
              },
              layout: "fixed",
            }),
          ],
        },

        // Page 17
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        createNestedTable("INSPECTIONS", tabledata14_7, {
                          height: 3500,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable("NOISE COMPLAINTS", tabledata14_8, {
                          height: 3500,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable("FACILITIES", tabledata14_9, {
                          height: 3500,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                  ],
                }),
              ],

              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE },
                bottom: { style: BorderStyle.NONE },
                left: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
              },
              layout: "fixed",
            }),

            createParagraph("", {
              bold: true,
              spacingbefore: 100,
              spacingafter: 100,
            }),

            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        createNestedTable("KEY CONTROL", tabledata14_10, {
                          height: 2000,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable("LOST AND FOUND", tabledata14_11, {
                          height: 2000,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable("PATROLS", tabledata14_12, {
                          height: 2000,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                  ],
                }),
              ],

              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE },
                bottom: { style: BorderStyle.NONE },
                left: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
              },
              layout: "fixed",
            }),

            createParagraph("", {
              bold: true,
              spacingbefore: 100,
              spacingafter: 100,
            }),

            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        createNestedTable("LOGS", tabledata14_13, {
                          height: 3700,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable("PUBLIC RELATIONS", tabledata14_14, {
                          height: 3700,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                    new TableCell({
                      children: [
                        createNestedTable("SECURITY AND LAW", tabledata14_15, {
                          height: 3700,
                        }),
                      ],
                      margins: tableMargins,
                      preferredWidth: preferredWidth,
                    }),
                  ],
                }),
              ],

              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                top: { style: BorderStyle.NONE },
                bottom: { style: BorderStyle.NONE },
                left: { style: BorderStyle.NONE },
                right: { style: BorderStyle.NONE },
                insideHorizontal: { style: BorderStyle.NONE },
                insideVertical: { style: BorderStyle.NONE },
              },
              layout: "fixed",
            }),
          ],
        },

        // Page 18
        {
          properties: {
            page: commonPageProperties,
          },
          children: [
            createHeading("15.0 Live Guard Monitoring", { bold: true }),
            createParagraph(
              "At Pillar Security, we understand the paramount importance of ensuring the safety and security of residential properties across the Greater Toronto Area (GTA). To address this critical need, we are proud to introduce our cutting-edge Live Video Monitoring solutions, developed in collaboration with our esteemed sister company, Pillar Technologies.",
              { spacingbefore: 150, spacingafter: 200 }
            ),
            createParagraph("Solution Overview:", {
              bold: true,
              spacingafter: 200,
            }),

            createParagraph(
              "Our Live Video Monitoring solutions are designed to provide comprehensive security coverage for residential properties, combining advanced hardware, sophisticated software/analytics, and human intelligence. By leveraging this powerful combination, we deliver a holistic and customized approach to safeguarding your property.",
              { spacingafter: 100 }
            ),

            ///IMAGE
            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer18_1, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 350,
                    height: 175,
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 0.7 * 1000000,
                    },
                    verticalPosition: {
                      offset: 2.6 * 1000000,
                    },
                    wrap: {
                      type: TextWrappingType.SQUARE,
                      side: TextWrappingSide.RIGHT,
                    },
                    margins: {
                      top: 20,
                      bottom: 20,
                      left: 100000,
                      right: 100000,
                    },
                  },
                }),
              ],
              alignment: AlignmentType.LEFT, // Align image to the right
            }),

            createParagraph(
              "Monitoring Staff Performance and Ensuring Health and Safety:",
              { bold: true, spacingafter: 200 }
            ),
            createParagraph(
              "One key aspect of our solution involves strategically installing cameras on-site, with a particular focus on critical areas such as the concierge desk and other accessible locations where our security guards are stationed. This deployment allows us to closely monitor our staff, providing real-time insights into their performance and ensuring adherence to established protocols.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "Moreover, our Live Video Monitoring system serves as a crucial tool for health and safety management. By capturing and analyzing video footage, we can proactively identify potential hazards, promptly respond to emergencies, and implement preventive measures. This capability not only safeguards the well-being of your residents but also helps mitigate liability risks associated with accidents or security breaches.",
              { spacingafter: 200 }
            ),
            createParagraph("Virtual Concierge Service:", {
              bold: true,
              spacingafter: 200,
            }),
            createParagraph(
              "In addition to staff monitoring, our Live Video Monitoring solutions offer a virtual concierge service during off-hours. We enable remote access control and visitor management, granting authorized entry to visitors, contractors, or patrol services. This feature provides round-the-clock security coverage, ensuring that your property remains protected even outside regular working hours.",
              { spacingafter: 200 }
            ),
            createParagraph(
              "By leveraging our Live Video Monitoring solutions, you can enhance the security of your residential property, ensuring the safety of your residents and the preservation of your assets. Our comprehensive approach, encompassing hardware, software/analytics, and human intelligence, guarantees proactive monitoring, staff performance management, and efficient access control.",
              { spacingafter: 200 }
            ),

            ///IMAGE
            new Paragraph({
              children: [
                new ImageRun({
                  data: pImageBuffer18_2, // Use ArrayBuffer for the image data
                  transformation: {
                    width: 360,
                    height: 175,
                  },
                  floating: {
                    horizontalPosition: {
                      offset: 3.6 * 1000000,
                    },
                    verticalPosition: {
                      offset: 5 * 1000000,
                    },
                    wrap: {
                      type: TextWrappingType.SQUARE,
                      side: TextWrappingSide.LEFT,
                    },
                    margins: {
                      top: 20,
                      bottom: 20,
                      left: 100000,
                      right: 100000,
                    },
                  },
                }),
              ],
              alignment: AlignmentType.RIGHT, // Align image to the right
            }),
          ],
        },

        // Page 19
        {
          properties: {
            page: commonPageProperties,
          },

          children: [
            createHeading("REFERENCES", { bold: true }),

            ...data.references.map((reference, index) => [
              createParagraph(reference.companyName, {
                bold: true,
                spacingbefore: 300,
              }),
              createParagraph(reference.address + " " + reference.city + " " + reference.postalCode, {
                bold: true,
              }),
              createTabStopText(reference.managerName, reference.phone ? reference.phone : ""),
              createParagraph("Property Manager"),
            ]).flat(), // Flatten the resulting array to avoid nested arrays

            
          ],
        },
      ],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, "Proposal.docx");
  };

  return (
    <div>
      <button
        type="button"
        onClick={generateDocument}
        className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 mx-auto block"
      >
        Download Proposal
      </button>
    </div>
  );
};

export default ExportData;
