import { put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import * as types from "../actions/proposalTypes";
import * as actions from "../actions/proposalActions";
import config from "../../config";

function* fetchProposal() {
  try {
    const response = yield call(axios.post, config.APIURL.proposalapi.dactive, {});
    yield put(actions.fetchProposalSuccess(response));
  } catch (error) {
    yield put(actions.fetchProposalFailure(error.message));
  }
}

function* fetchProposalById(action) {
  console.log(action);
  
  try {
    const response = yield call(axios.post, config.APIURL.proposalapi.displaybyid, action.payload);
    yield put(actions.fetchProposalByIdSuccess(response));
  } catch (error) {
    yield put(actions.fetchProposalByIdFailure(error.message));
  }
}

function* addProposal(action) {
  try {
    const response = yield call(axios.post, config.APIURL.proposalapi.create, action.payload);
    const recordId = response.data.id;
    yield put(actions.addProposalSuccess(response.data));

    if (typeof action.navigate === "function") {
      action.navigate(`/displayproposal/${recordId}`);
    } else {
      console.error("navigate function is not defined");
    }
  } catch (error) {
    yield put(actions.addProposalFailure(error.message));
  }
}

function* updateProposal(action) {
  try {
    const response = yield call(axios.post, config.APIURL.proposalapi.update, action.payload);
    const recordId = response.data.id;
    yield put(actions.updateProposalSuccess(response.data));

    if (typeof action.navigate === "function") {
      action.navigate(`/displayproposal/${recordId}`);
    } else {
      console.error("navigate function is not defined");
    }
  } catch (error) {
    yield put(actions.updateProposalFailure(error.message));
  }
}

function* deleteProposal(action) {
  try {
    const response = yield call(axios.post, config.APIURL.proposalapi.activate, action.payload);
    if (response.data.data === 1) { 
      yield put(actions.deleteProposalSuccess(action.payload));
    } else {
      yield put(actions.deleteProposalFailure('Failed to delete the proposal'));
    }
  } catch (error) {
    yield put(actions.deleteProposalFailure(error.message));
  }
}

function* proposalSaga() {
  yield takeEvery(types.FETCH_PROPOSAL_REQUEST, fetchProposal);
  yield takeEvery(types.FETCH_PROPOSAL_BYID_REQUEST, fetchProposalById);
  yield takeEvery(types.ADD_PROPOSAL_REQUEST, addProposal);
  yield takeEvery(types.UPDATE_PROPOSAL_REQUEST, updateProposal);
  yield takeEvery(types.DELETE_PROPOSAL_REQUEST, deleteProposal);
}

export default proposalSaga;
