export const FETCH_PROPOSAL_REQUEST = 'FETCH_PROPOSAL_REQUEST';
export const FETCH_PROPOSAL_SUCCESS = 'FETCH_PROPOSAL_SUCCESS';
export const FETCH_PROPOSAL_FAILURE = 'FETCH_PROPOSAL_FAILURE';

export const FETCH_PROPOSAL_BYID_REQUEST = 'FETCH_PROPOSAL_BYID_REQUEST';
export const FETCH_PROPOSAL_BYID_SUCCESS = 'FETCH_PROPOSAL_BYID_SUCCESS';
export const FETCH_PROPOSAL_BYID_FAILURE = 'FETCH_PROPOSAL_BYID_FAILURE';

export const ADD_PROPOSAL_REQUEST = 'ADD_PROPOSAL_REQUEST';
export const ADD_PROPOSAL_SUCCESS = 'ADD_PROPOSAL_SUCCESS';
export const ADD_PROPOSAL_FAILURE = 'ADD_PROPOSAL_FAILURE';

export const UPDATE_PROPOSAL_REQUEST = 'UPDATE_PROPOSAL_REQUEST';
export const UPDATE_PROPOSAL_SUCCESS = 'UPDATE_PROPOSAL_SUCCESS';
export const UPDATE_PROPOSAL_FAILURE = 'UPDATE_PROPOSAL_FAILURE';

export const DELETE_PROPOSAL_REQUEST = 'DELETE_PROPOSAL_REQUEST';
export const DELETE_PROPOSAL_SUCCESS = 'DELETE_PROPOSAL_SUCCESS';
export const DELETE_PROPOSAL_FAILURE = 'DELETE_PROPOSAL_FAILURE';

export const CLEAR_PROPOSAL_DATA = 'CLEAR_PROPOSAL_DATA';

export const SET_SORT_CONFIG = 'SET_SORT_CONFIG';
                