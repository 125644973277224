
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import * as types from '../actions/adminTypes';
import * as actions from '../actions/adminActions';
import config from '../../config';

// const API_URL = 'https://tsapi.schedulemycrew.ca/api/emploginapi/';
// const API_URL = 'http://localhost:5080/api/admin/';
// const API_URL = 'http://localhost:60200/api/emploginapi/';

function* adminLogin(action) {
    try {
        const response = yield call(axios.post, config.APIURL.emploginapi.login, action.payload);
        // const response = yield call(axios.post, API_URL + "login", action.payload);
        yield put(actions.adminLoginSuccess(response.data));
        // return response.data;
    } catch (error) {
        yield put(actions.adminLoginFailure(error.message));
        // throw error;
    }
}

function* refreshTokenSaga(action) {
    try {
      const response = yield call( axios.post, config.APIURL.emploginapi.refresh, action.payload );
  
      // Save the new access token
      const accessToken = response.data.accessToken;
      const refreshToken = response.data.refreshToken;
      yield put(actions.refreshTokenSuccess(accessToken));
  
      // Optionally store the new token in localStorage or redux state
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
  
      return accessToken;
    } catch (error) {
      yield put(actions.refreshTokenFailure(error.message));
      return null;
    }
  }
  

// function* fetchAdmin() {
//     try {
//         const response = yield call(axios.post, API_URL + "display");
//         yield put(actions.fetchAdminSuccess(response.data));
//     } catch (error) {
//         yield put(actions.fetchAdminFailure(error.message));
//     }
// }

// function* addAdmin(action) {
//     try {
//         const response = yield call("https://tsapi.schedulemycrew.ca/api/proposalapi/create", action.payload);
//         // const response = yield call("http://localhost:5080/api/proposal/create", action.payload);
//         yield put(actions.addAdminSuccess(response.data));
//     } catch (error) {
//         yield put(actions.addAdminFailure(error.message));
//     }
// }

// function* updateAdmin(action) {
//     try {
//         const response = yield call(
//             axios.put,
//             `${API_URL}/${action.payload.id}`,
//             action.payload
//         );
//         yield put(actions.updateAdminSuccess(response.data));
//     } catch (error) {
//         yield put(actions.updateAdminFailure(error.message));
//     }
// }

// function* deleteAdmin(action) {
//     try {
//         yield call(axios.delete, `${API_URL}/${action.payload}`);
//         yield put(actions.deleteAdminSuccess(action.payload));
//     } catch (error) {
//         yield put(actions.deleteAdminFailure(error.message));
//     }
// }

function* adminSaga() {
    yield takeEvery(types.ADMIN_LOGIN_REQUEST, adminLogin);
    yield takeEvery(types.REFRESH_TOKEN_REQUEST, refreshTokenSaga);
    // yield takeEvery(types.FETCH_ADMIN_REQUEST, fetchAdmin);
    // yield takeEvery(types.ADD_ADMIN_REQUEST, addAdmin);
    // yield takeEvery(types.UPDATE_ADMIN_REQUEST, updateAdmin);
    // yield takeEvery(types.DELETE_ADMIN_REQUEST, deleteAdmin);
}

export default adminSaga;
                