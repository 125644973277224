import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import * as types from '../actions/authTypes';
import * as actions from '../actions/authActions';

// Fake API URL
const API_URL = 'http://localhost:5000/api/auth';  // Replace with actual backend URL

// Registration Saga
function* registerSaga(action) {
  try {
    const response = yield call(axios.post, `${API_URL}/register`, action.payload);
    const { id } = response.data;
    yield put(actions.registerSuccess(id));
  } catch (error) {
    yield put(actions.registerFailure(error.message));
  }
}

// Login Saga
function* loginSaga(action) {
  try {
    const response = yield call(axios.post, `${API_URL}/login`, action.payload);
    if (response.data.success) {
      yield put(actions.loginSuccess());
    } else {
      throw new Error('Invalid credentials');
    }
  } catch (error) {
    yield put(actions.loginFailure(error.message));
  }
}

export default function* authSaga() {
  yield takeEvery(types.REGISTER_REQUEST, registerSaga);
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
}
