import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { PaymentProvider } from "./context/PaymentContext";
import LoginPage from "./container/LoginPage";
import ProtectedRoute from "./utils/ProtectedRoute";
import ProposalPlan from "./container/ProposalPlan";
import ProposalList from "./container/ProposalList";
import DisplayProposal from "./container/DisplayProposal";
import ProposalPlanEdit from "./container/ProposalPlanEdit";

const protectedRoutes = [
  { path: "/proposal", component: ProposalPlan },
  { path: "/displayproposal/:id", component: DisplayProposal },
  { path: "/proposallist", component: ProposalList },
  { path: "/editproposal/:id", component: ProposalPlanEdit },
];

function App() {
  return (
    <AuthProvider>
      <PaymentProvider>
        <Router>
          <div className="container mx-auto">
            <Routes>
              <Route path="/" element={<LoginPage />} />
              {protectedRoutes.map(({ path, component: Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <ProtectedRoute>
                      <Component />
                    </ProtectedRoute>
                  }
                />
              ))}
              <Route path="*" element={<LoginPage />} />
            </Routes>
          </div>
        </Router>
      </PaymentProvider>
    </AuthProvider>
  );
}

export default App;


// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { AuthProvider } from "./context/AuthContext";
// import ProposalPlan from "./container/ProposalPlan";
// import { PaymentProvider } from "./context/PaymentContext";
// import ProposalList from "./container/ProposalList";
// import LoginPage from "./container/LoginPage";
// import ProtectedRoute from "./utils/ProtectedRoute";
// import DisplayProposal from "./container/DisplayProposal";
// import ProposalPlanEdit from "./container/ProposalPlanEdit";

// function App() {
//   return (
//     <AuthProvider>
//       <PaymentProvider>
//         <Router>
//           <div className="container mx-auto">
//             <Routes>
//               <Route path="/" element={<LoginPage />} />
//               <Route
//                 path="/proposal"
//                 element={
//                   <ProtectedRoute>
//                     <ProposalPlan />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/displayproposal/:id"
//                 element={
//                   <ProtectedRoute>
//                     <DisplayProposal />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/proposallist"
//                 element={
//                   <ProtectedRoute>
//                     <ProposalList />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/editproposal/:id"
//                 element={
//                   <ProtectedRoute>
//                     <ProposalPlanEdit />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route path="*" element={<LoginPage />} />
//             </Routes>
//           </div>
//         </Router>
//       </PaymentProvider>
//     </AuthProvider>
//   );
// }

// export default App;
