
import * as types from './adminTypes';


export const adminLoginRequest = (data) => ({
    type: types.ADMIN_LOGIN_REQUEST,
    payload: data,
});

export const adminLoginSuccess = (data) => ({
    type: types.ADMIN_LOGIN_SUCCESS,
    payload: data,
});

export const adminLoginFailure = (error) => ({
    type: types.ADMIN_LOGIN_FAILURE,
    payload: error,
});

export const refreshTokenRequest = (data) => ({
    type: types.REFRESH_TOKEN_REQUEST,
    payload: data,
});

export const refreshTokenSuccess = (data) => ({
    type: types.REFRESH_TOKEN_SUCCESS,
    payload: data,
});

export const refreshTokenFailure = (error) => ({
    type: types.REFRESH_TOKEN_FAILURE,
    payload: error,
});

export const fetchAdminRequest = () => ({
    type: types.FETCH_ADMIN_REQUEST,
});

export const fetchAdminSuccess = (data) => ({
    type: types.FETCH_ADMIN_SUCCESS,
    payload: data,
});

export const fetchAdminFailure = (error) => ({
    type: types.FETCH_ADMIN_FAILURE,
    payload: error,
});

export const addAdminRequest = (data) => ({
    type: types.ADD_ADMIN_REQUEST,
    payload: data,
});

export const addAdminSuccess = (data) => ({
    type: types.ADD_ADMIN_SUCCESS,
    payload: data,
});

export const addAdminFailure = (error) => ({
    type: types.ADD_ADMIN_FAILURE,
    payload: error,
});

export const updateAdminRequest = (data) => ({
    type: types.UPDATE_ADMIN_REQUEST,
    payload: data,
});

export const updateAdminSuccess = (data) => ({
    type: types.UPDATE_ADMIN_SUCCESS,
    payload: data,
});

export const updateAdminFailure = (error) => ({
    type: types.UPDATE_ADMIN_FAILURE,
    payload: error,
});

export const deleteAdminRequest = (id) => ({
    type: types.DELETE_ADMIN_REQUEST,
    payload: id,
});

export const deleteAdminSuccess = (id) => ({
    type: types.DELETE_ADMIN_SUCCESS,
    payload: id,
});

export const deleteAdminFailure = (error) => ({
    type: types.DELETE_ADMIN_FAILURE,
    payload: error,
});
                